import { Box, Typography, useMediaQuery } from "@mui/material"
import React, { useState } from "react"
import LoginForm from "../../login/LoginForm"
import Copyright from "../../login/Copyright"
import LogoDisplay from "../../login/LogoDisplay"
import * as amplitude from "@amplitude/analytics-browser"
import { gql, useMutation } from "urql"
import ErrorBanner from "../../banners/ErrorBanner"
import theme from "../../theme"

const LoginScreen = () => {
  const loginQuery = gql`
    mutation Login($email: String!, $password: String!) {
      login(email: $email, password: $password) {
        code
        user {
          id
          name
        }
        success
        exp
      }
    }
  `

  const [, login] = useMutation(loginQuery)
  const showDisplayLogo = useMediaQuery(theme.breakpoints.up("lg"))

  const [showLoginFailedMessage, setShowLoginFailedMessage] = useState(false)

  const attemptLogin = (email: string, password: string) => {
    setShowLoginFailedMessage(false)
    login(
      { email, password },
      {
        fetchOptions: {
          credentials: "include",
          mode: "cors"
        }
      }
    )
      .then((result) => {
        if (result.data?.login.code !== "LOGIN_SUCCESS") {
          setShowLoginFailedMessage(true)
        } else {
          // get cookie from response header

          const userId = result.data.login.user.id
          const userName = result.data.login.user.name

          localStorage.setItem("user-id", userId)
          localStorage.setItem("user-name", userName)
          amplitude.track("login", {
            userId,
            userName,
            userEmail: email
          })
          location.reload()
        }
      })
      .catch((error) => {
        setShowLoginFailedMessage(true)
        console.error(error)
      })
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh"
      }}
      display="flex"
      flexDirection="row"
      justifyContent="center"
    >
      <Box
        sx={{
          maxWidth: "960px",
          minWidth: "640px",
          width: {
            xs: "100%",
            sm: "100%",
            md: "100%",
            lg: "45%",
            xl: "45%"
          }
        }}
        display="flex"
        flexDirection="column"
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          flexGrow={1}
        >
          <Box
            sx={{
              maxWidth: "480px",
              width: "360px",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center"
            }}
          >
            {showLoginFailedMessage && (
              <Box
                sx={{
                  marginBottom: "11px"
                }}
              >
                <ErrorBanner>
                  <Typography color="error" variant="body2">
                    Login attempt failed. Please check that your email and
                    password are correct and try logging in again.
                  </Typography>
                </ErrorBanner>
              </Box>
            )}
            <LoginForm
              onSubmit={(data) => {
                attemptLogin(data.email, data.password)
              }}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="row"
          marginBottom="24px"
        >
          <Copyright />
        </Box>
      </Box>
      {showDisplayLogo && (
        <Box display="flex" flexGrow={1}>
          <LogoDisplay />
        </Box>
      )}
    </Box>
  )
}

export default LoginScreen
