import React from "react"
import { Alert, Box, SxProps } from "@mui/material"
import CautionFill from "../../icons/CautionFill"

type ErrorBannerProps = {
  children: React.ReactNode
  sx?: SxProps
}

const ErrorBanner: React.FC<ErrorBannerProps> = ({ children, sx }) => {
  return (
    <Box justifyContent="center" sx={sx}>
      <Alert
        icon={<CautionFill />}
        severity="error"
        sx={{
          color: "error.main",
          borderRadius: "8px"
        }}
      >
        {children}
      </Alert>
    </Box>
  )
}

export default ErrorBanner
