import React from "react"
import { Alert, Box } from "@mui/material"
import TrashFill from "../../icons/TrashFill"

type DiscardBannerProps = {
  children: React.ReactNode
}

const DiscardBanner: React.FC<DiscardBannerProps> = ({ children }) => {
  return (
    <Box justifyContent="center" alignItems="center">
      <Alert
        icon={
          <TrashFill
            sx={{
              width: "16px",
              height: "16px",
              color: "secondary.main"
            }}
          />
        }
        severity="success"
        sx={{
          height: "32px",
          backgroundColor: "asset.main",
          color: "secondary.main",
          borderRadius: "4px",
          alignItems: "center"
        }}
      >
        {children}
      </Alert>
    </Box>
  )
}

export default DiscardBanner
