import React from "react"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { useLocation } from "react-router-dom"
import { useForm, Controller } from "react-hook-form"

type FormValues = {
  email: string
}

interface Props {
  onSubmit: (email: string) => void
}

const ResetPasswordForm = (props: Props) => {
  const location = useLocation()

  const initialEmail = location.state?.initialEmail

  const {
    control,
    handleSubmit,
    formState: { isValid }
  } = useForm<FormValues>({
    mode: "onBlur"
  })

  const handleFormSubmit = (data: FormValues) => {
    props.onSubmit(data.email)
  }

  return (
    <Box display="flex" flexDirection="column" gap="32px">
      <Typography variant="body1">
        Enter your email address to reset your password
      </Typography>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Box display="flex" flexDirection="column" gap="32px">
          <Controller
            name="email"
            rules={{
              required: "Email is required",
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Please enter a valid email format"
              }
            }}
            control={control}
            defaultValue={initialEmail || ""}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                variant="outlined"
                type="email"
                placeholder="name@company.com"
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />

          <Button
            variant="contained"
            type="submit"
            disabled={!isValid}
            sx={{
              height: "36px",
              boxShadow: "none"
            }}
          >
            Submit
          </Button>
        </Box>
      </form>
    </Box>
  )
}

export default ResetPasswordForm
