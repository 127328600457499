import React from "react"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const ArrowLeft = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1302_77236)">
          <mask
            id="mask0_1302_77236"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
          >
            <path
              d="M10.137 6L4 12M4 12L10.137 18M4 12H20"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="square"
            />
          </mask>
          <g mask="url(#mask0_1302_77236)">
            <rect width="24" height="24" fill="currentColor" />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_1302_77236">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  )
}

export default ArrowLeft
