/* eslint-disable indent */
import { SupportedAssetType } from "../models/campaign"
import { isEmpty, every, isUndefined, isNull, isArray } from "lodash"
import { Ratio, ZonePlacement } from "../models/zoneplacement"

export const getUserId = () => {
  const id = localStorage.getItem("user-id")
  if (!id) {
    return null
  }
  return Number(id)
}

export const isSupportedAsset = (
  fileType: string
): fileType is SupportedAssetType =>
  ["video/mp4", "image/png", "image/jpeg"].includes(fileType)

export const getUserName = () => {
  const name = localStorage.getItem("user-name")
  if (!name) {
    return null
  }
  return name
}

export const getInitials = (name: string | undefined | null): string => {
  if (!name) {
    return ""
  }

  const parts = name.split(" ")

  if (parts.length === 1) {
    return parts[0].charAt(0).toUpperCase()
  }

  const firstName = parts[0]
  const lastName = parts[parts.length - 1]

  return (firstName.charAt(0) + lastName.charAt(0)).toUpperCase()
}

export const getFirstAndLastName = (
  name: string | undefined | null
): { firstName: string; lastName: string } => {
  let firstName = ""
  let lastName = ""

  if (name) {
    const parts = name.split(" ")

    if (parts.length === 1) {
      firstName = parts[0]
    } else {
      firstName = parts[0]
      lastName = parts[parts.length - 1]
    }
  }

  return { firstName, lastName }
}

export const isEmptyObject = (obj: Record<string, unknown>): boolean => {
  return every(obj, (value: unknown) => {
    return (
      isUndefined(value) ||
      isNull(value) ||
      (isArray(value) && isEmpty(value)) ||
      value === ""
    )
  })
}

export const formatSeconds = (seconds: number) => {
  return `00:${seconds.toString().padStart(2, "0")}`
}

export const getAdjustedAspectRatioString = (
  widthPx: number,
  heightPx: number
) => {
  const aspectRatio16x9 = 16 / 9
  const aspectRatio9x16 = 9 / 16
  const aspectRatioDeviationThreshold = 0.05
  const actualAspectRatio = widthPx / heightPx
  const gcd = gcdCalc(widthPx, heightPx)

  if (
    Math.abs(actualAspectRatio - aspectRatio16x9) <
    aspectRatioDeviationThreshold
  ) {
    return "16:9"
  }

  if (
    Math.abs(actualAspectRatio - aspectRatio9x16) <
    aspectRatioDeviationThreshold
  ) {
    return "9:16"
  }

  return `${widthPx / gcd}:${heightPx / gcd}`
}

/**
 * Helper function to calculate the greatest common divisor (GCD)
 * @param {number} a
 * @param {number} b
 * @returns {number} The GCD of a and b
 */
function gcdCalc(a: number, b: number): number {
  if (!b) {
    return a
  }
  return gcdCalc(b, a % b)
}

export const generateDurationList = (
  minSeconds: number,
  maxSeconds: number
): number[] => {
  const result: number[] = []

  for (let i = minSeconds; i <= maxSeconds; i++) {
    result.push(i)
  }

  return result
}

export const extractStringFirstToken = (str: string): string => {
  return str.split(" ")[0]
}

export const alphabeticalSort = (lhs: string, rhs: string): number => {
  return lhs.localeCompare(rhs, undefined, {
    // Sort numbers in the string by their numeric value
    numeric: true,
    sensitivity: "base"
  })
}

export type ZonePlacementSelection = {
  zoneId: number
  placementIds: number[]
}

/**
 * Converts API zone and placement IDs to ZonePlacementSelection array
 */
export function convertToZonePlacements(
  zoneList: ZonePlacement[],
  selectedZoneIds: number[],
  selectedPlacementIds: number[]
): ZonePlacementSelection[] {
  return zoneList.reduce<ZonePlacementSelection[]>((acc, zone) => {
    if (selectedZoneIds.includes(zone.id)) {
      // If zone is selected, add all its placements
      acc.push({
        zoneId: zone.id,
        placementIds: zone.placements?.map((p) => p.id) || []
      })
    } else if (zone.placements) {
      // For non-selected zones, check for partial selections
      const zonePlacementIds = zone.placements
        .filter((placement) => selectedPlacementIds.includes(placement.id))
        .map((placement) => placement.id)

      if (zonePlacementIds.length > 0) {
        acc.push({
          zoneId: zone.id,
          placementIds: zonePlacementIds
        })
      }
    }
    return acc
  }, [])
}

/**
 * Updates or adds a zone's placements in the zone placements array
 */
export function updateZonePlacementSelection(
  currentSelections: Array<{
    zoneId: number
    placementIds: (number | undefined)[]
  }>,
  zoneId: number,
  newPlacementIds: (number | undefined)[]
): Array<{ zoneId: number; placementIds: (number | undefined)[] }> {
  const existingIndex = currentSelections.findIndex((s) => s.zoneId === zoneId)

  return existingIndex >= 0
    ? currentSelections.map((item, index) =>
        index === existingIndex
          ? { ...item, placementIds: newPlacementIds }
          : item
      )
    : [...currentSelections, { zoneId, placementIds: newPlacementIds }]
}

/**
 * Gets unique ratios from both fully and partially selected zones
 */
export function getSelectedZoneRatios(
  selectedZones: ZonePlacement[],
  selectedZonePlacements: Array<{
    zoneId: number
    placementIds: (number | undefined)[]
  }>,
  zoneList: ZonePlacement[]
): Set<Ratio> {
  return new Set([
    ...selectedZones.map((zone) => zone.ratio),
    ...selectedZonePlacements
      .map((selection) => {
        const zone = zoneList?.find((z) => z.id === selection.zoneId)
        return zone?.ratio
      })
      .filter((ratio): ratio is Ratio => ratio !== undefined)
  ])
}

export const extractZonePlacementIdsForSubmission = (
  selectedZones: { id: number }[],
  selectedZonePlacements: {
    zoneId: number
    placementIds: (number | undefined)[]
  }[]
): { zone_ids: number[]; placement_ids: number[] } => {
  // Get all selected zone IDs
  const zone_ids = selectedZones.map((zone) => zone.id)

  // Get placement IDs only from zones that are NOT in selectedZones
  const placement_ids = selectedZonePlacements
    .filter((placement) => !zone_ids.includes(placement.zoneId))
    .flatMap((placement) =>
      placement.placementIds.filter((id): id is number => id !== undefined)
    )

  return { zone_ids, placement_ids }
}

export const getZoneRatio = (
  selectedZones: Partial<ZonePlacement>[],
  selectedZonePlacements: { zoneId: number }[],
  campaignZonePlacementList?: ZonePlacement[],
  defaultRatio: Ratio = "16:9"
): Ratio => {
  if (selectedZones?.length > 0 && selectedZones[0].ratio) {
    return selectedZones[0].ratio
  }

  if (selectedZonePlacements?.length === 0 || !campaignZonePlacementList) {
    return defaultRatio
  }

  return (
    campaignZonePlacementList.find(
      (zone) => zone.id === selectedZonePlacements[0].zoneId
    )?.ratio ?? defaultRatio
  )
}

export const getMaxDurationZoneId = (
  selectedZones: Partial<ZonePlacement>[],
  selectedZonePlacements: { zoneId: number }[],
  campaignZonePlacementList: ZonePlacement[] = []
) => {
  // Combine zones from both selections
  const allZones = [
    ...selectedZones,
    ...selectedZonePlacements
      .map((placement) =>
        campaignZonePlacementList.find((zone) => zone.id === placement.zoneId)
      )
      .filter((zone): zone is ZonePlacement => zone !== undefined)
  ]

  // Find zone with smallest max duration
  return allZones.reduce((prev, curr) => {
    const prevDuration = prev.max_creative_duration_ms ?? Number.MAX_VALUE
    const currDuration = curr.max_creative_duration_ms ?? Number.MAX_VALUE

    return prevDuration < currDuration ? prev : curr
  }).id
}

export const getMaxDurationSeconds = (
  selectedZones: ZonePlacement[],
  selectedZonePlacements: { zoneId: number }[],
  zonePlacementList: ZonePlacement[] = []
): number => {
  const allZones = [
    ...selectedZones,
    ...selectedZonePlacements
      .map((placement) =>
        zonePlacementList.find((zone) => zone.id === placement.zoneId)
      )
      .filter((zone): zone is ZonePlacement => zone !== undefined)
  ]

  if (allZones?.length === 0) {
    return 30
  }

  return Math.floor(
    Math.min(
      ...allZones.map((zone) => zone.max_creative_duration_ms ?? 30000)
    ) / 1000
  )
}
