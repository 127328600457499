import React from "react"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const CircleAdd = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1306_77972)">
          <mask
            id="mask0_1306_77972"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
          >
            <circle cx="12" cy="12" r="8.25" stroke="black" strokeWidth="1.5" />
            <path d="M12 7V17M17 12H7" stroke="black" strokeWidth="1.5" />
          </mask>
          <g mask="url(#mask0_1306_77972)">
            <rect width="24" height="24" fill="currentColor" />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_1306_77972">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  )
}

export default CircleAdd
