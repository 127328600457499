import React from "react"
import TextField from "@mui/material/TextField"
import IconButton from "@mui/material/IconButton"
import InputAdornment from "@mui/material/InputAdornment"
import MagnifyingGlass from "../../icons/MagnifyingGlass/MagnifyingGlass"
import CircleXFill from "../../icons/CircleXFill"

interface Props {
  searchInput?: string
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onClear: () => void
  componentId?: string
  placeholder?: string
  width?: string
}

const SearchInput = ({
  searchInput,
  onChange,
  onClear,
  componentId,
  placeholder,
  width = "200px"
}: Props) => {
  return (
    <TextField
      id={componentId}
      type="text"
      placeholder={placeholder || "Search"}
      value={searchInput}
      onChange={onChange}
      sx={{
        width: width,
        "&:hover .MuiInputAdornment-root .MuiSvgIcon-root ,&:focus-within .MuiInputAdornment-root .MuiSvgIcon-root":
          {
            color: "fileInput.dark"
          },
        ".MuiInputAdornment-root .MuiSvgIcon-root": {
          color: "secondary.dark"
        }
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <MagnifyingGlass fontSize="small" />
          </InputAdornment>
        ),
        endAdornment: searchInput ? (
          <InputAdornment position="start">
            <IconButton aria-label="search table" edge="end" onClick={onClear}>
              <CircleXFill fontSize="small" />
            </IconButton>
          </InputAdornment>
        ) : null
      }}
    />
  )
}

export default SearchInput
