import Copyright from "../../login/Copyright"
import { Box, Button, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import React from "react"

const WelcomeScreen = () => {
  const navigate = useNavigate()
  return (
    <>
      <video
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          objectFit: "cover",
          padding: "0px",
          margin: "0px",
          zIndex: 1
        }}
        muted
        autoPlay
        loop
        src="/bg-gradient.mp4"
      >
        <img
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            padding: "0px",
            margin: "0px"
          }}
          src="/sizzle_bg.svg"
          alt="Background image"
        />
      </video>
      <Box
        sx={{
          width: "100vw",
          height: "100vh"
        }}
        display="flex"
        justifyContent="space-around"
        alignItems="center"
        flexDirection="column"
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{
            backgroundColor: "white",
            borderRadius: "8px",
            padding: "24px 60px",
            width: "40%",
            minWidth: "600px",
            maxWidth: "900px"
          }}
          gap="32px"
          zIndex={2}
        >
          <img
            src="/square_logo.svg"
            alt="GTV logo"
            style={{
              width: "72px",
              height: "72px",
              marginTop: "32px"
            }}
          />
          <Typography
            sx={{
              fontSize: "18px",
              lineHeight: "1.5em",
              textAlign: "center"
            }}
          >
            Welcome to your new Content Management System for in-store digital
            marketing. We&apos;ve retired the legacy CMS to continue improving
            your CMS experience.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{
              borderRadius: "8px",
              padding: "12px 24px",
              width: "100%",
              marginBottom: "64px"
            }}
            onClick={() => {
              navigate("/")
            }}
          >
            Get Started
          </Button>
          <Copyright />
        </Box>
      </Box>
    </>
  )
}

export default WelcomeScreen
