import React from "react"
import { Box, IconButton, Typography } from "@mui/material"
import { useFormContext } from "react-hook-form"
import { CampaignFormModel } from "../../../models/campaign"
import PlayFill from "../../icons/PlayFill"
import PauseFill from "../../icons/PauseFill"
import TrashOutline from "../../icons/TrashOutline"
import ImagePlaySection from "../ImagePlaySection"
import { ZonePlacement } from "../../../models/zoneplacement"
import { getZoneRatio } from "../../../utils"

type AssetPreviewProps = {
  assetUrl: string | null
  assetType: string | null
  assetDimension?: string
  videoDuration?: number | undefined
  handleDelete: () => void
  onDurationChange: (newDuration: number) => Promise<void>
  isCampaignEnded?: boolean
  minDuration?: number
  maxDuration?: number
  campaignZonePlacementList?: ZonePlacement[] | undefined
}

const AssetPreview = ({
  assetUrl,
  assetType,
  videoDuration,
  handleDelete,
  onDurationChange,
  isCampaignEnded,
  campaignZonePlacementList
}: AssetPreviewProps) => {
  const { watch } = useFormContext<CampaignFormModel>()
  const [playing, setPlaying] = React.useState(false)
  const [isHovered, setIsHovered] = React.useState(false)
  const videoRef = React.useRef<HTMLVideoElement>(null)

  const asset = watch("file.asset")
  const duration = watch("file.duration")
  const selectedZones = watch("selectedZones")
  const selectedZonePlacements = watch("selectedZonePlacements")
  const currentCamapignId: number | undefined =
    Number(window.location.pathname.split("/")[2]) || undefined

  const isDisabled = !asset && typeof currentCamapignId === "number"

  const currentRatio = getZoneRatio(
    selectedZones || [],
    selectedZonePlacements || [],
    campaignZonePlacementList
  )

  const renderDuration = (displayDuration: number | undefined) => (
    <Box
      display="flex"
      justifyContent="space-between"
      sx={{ width: "192px", height: "36px" }}
    >
      <Typography variant="body2">Duration:</Typography>
      <Typography variant="body2">
        {displayDuration !== undefined
          ? `${Math.round(displayDuration)} seconds`
          : "N/A"}
      </Typography>
    </Box>
  )

  const toggleVideo = () => {
    if (videoRef.current) {
      if (playing) {
        videoRef.current.pause()
      } else {
        videoRef.current.play()
      }
      setPlaying(!playing)
    }
  }
  if (selectedZones?.length === 0 && selectedZonePlacements?.length === 0) {
    return null
  }

  return (
    <Box display="flex" flexDirection="column" gap="12px">
      <Box
        display="flex"
        justifyContent="center"
        sx={{
          width: "100%",
          backgroundColor: currentRatio === "9:16" ? "asset.main" : "inherit",
          opacity: isCampaignEnded ? 0.5 : 1
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          sx={{
            width: currentRatio === "16:9" ? "100%" : "auto",
            maxHeight: "512px",
            position: "relative",
            borderRadius: "inherit"
          }}
        >
          {assetType === "video/mp4" && assetUrl && (
            <Box
              component="video"
              ref={videoRef}
              muted
              sx={{
                width: "100%",
                height: "100%",
                objectFit: currentRatio === "16:9" ? "cover" : "contain",
                borderRadius: "inherit"
              }}
            >
              <source src={assetUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </Box>
          )}

          {assetType === "video/mp4" && (
            <IconButton
              aria-label={playing ? "pause" : "play"}
              onClick={toggleVideo}
              data-testid="toggle-video"
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                backgroundColor: "white",
                opacity: playing && !isHovered ? 0 : 1,
                "&:hover": {
                  opacity: 1,
                  backgroundColor: "white"
                }
              }}
            >
              <PlayFill sx={{ display: playing ? "none" : "inline-block" }} />
              <PauseFill
                sx={{ display: playing && isHovered ? "inline-block" : "none" }}
              />
            </IconButton>
          )}

          {assetType !== "video/mp4" && assetUrl && (
            <Box
              component="img"
              src={assetUrl || ""}
              alt="asset preview"
              sx={{
                width: "100%",
                height: "100%",
                objectFit: currentRatio === "16:9" ? "cover" : "contain",
                borderRadius: "inherit"
              }}
            />
          )}
        </Box>
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        height="36px"
        sx={{ opacity: isCampaignEnded ? 0.5 : 1 }}
      >
        {isDisabled ? (
          renderDuration(duration)
        ) : videoDuration !== undefined ? (
          renderDuration(videoDuration)
        ) : (
          <ImagePlaySection onDurationChange={onDurationChange} />
        )}

        <IconButton
          aria-label="delete"
          disabled={isCampaignEnded}
          onClick={handleDelete}
          sx={{
            padding: 0,
            gap: "2px"
          }}
        >
          <TrashOutline sx={{ fontSize: "16px" }} />
          <Typography
            variant="body2"
            sx={{ opacity: isCampaignEnded ? 0.5 : 1 }}
          >
            Remove Asset
          </Typography>
        </IconButton>
      </Box>
    </Box>
  )
}

export default AssetPreview
