import React from "react"
import { useWatch } from "react-hook-form"
import {
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from "@mui/material"
import { StoreTargetMapping, PropertyItem } from "../../../models/storetarget"

const ReviewStep = () => {
  const propertyName = useWatch({
    name: "propertyName"
  })

  const propertyItems: PropertyItem[] = useWatch({
    name: "propertyItems",
    defaultValue: []
  })

  const propertyType = useWatch({
    name: "propertyType"
  })

  const StoreTargetMappings: StoreTargetMapping[] = useWatch({
    name: "StoreTargetMappings"
  })

  const renderTable = (
    filteredData: StoreTargetMapping[],
    title: string,
    key: string | number
  ) => (
    <Box
      key={key}
      sx={{ display: "flex", flexDirection: "column", gap: "12px" }}
    >
      <Typography>{title}</Typography>
      <Box
        sx={{
          border: "1px solid",
          borderColor: "secondary.light",
          borderRadius: "8px"
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "25%", textAlign: "left" }}>
                Banner
              </TableCell>
              <TableCell sx={{ width: "25%", textAlign: "left" }}>
                Store
              </TableCell>
              <TableCell sx={{ width: "25%", textAlign: "left" }}>
                Location
              </TableCell>
              <TableCell sx={{ width: "25%", textAlign: "left" }}>
                {propertyName}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              "& .MuiTableCell-root": {
                borderBottom: "none"
              }
            }}
          >
            {filteredData.map((row) => (
              <TableRow key={row.storesId}>
                <TableCell>{row.bannerName}</TableCell>
                <TableCell>{row.store}</TableCell>
                <TableCell>{row.location}</TableCell>
                <TableCell>{row.propertyValue}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  )

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "40px",
        overflowY: "auto",
        gap: "24px"
      }}
    >
      <Typography
        variant="h5"
        sx={{ fontWeight: 600, color: "fileInput.dark" }}
      >
        Does everything look good?
      </Typography>

      {propertyItems.map((item, index) => {
        const filteredData = StoreTargetMappings.filter(
          (mapping) => mapping.propertyValue === item.value
        )
        return renderTable(filteredData, item.value, index)
      })}

      {propertyType === "multi-select" &&
        renderTable(
          StoreTargetMappings.filter(
            (mapping) => mapping.propertyValue === "Not Applicable"
          ),
          "Not Applicable",
          "not-applicable"
        )}
    </Box>
  )
}

export default ReviewStep
