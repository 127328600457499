import React from "react"
import { Box, CircularProgress, Backdrop, Typography } from "@mui/material"
interface Props {
  active: boolean
  title?: string
  content?: string
}

const Loader = ({ active, title, content }: Props) => (
  <Backdrop
    open={active}
    sx={{ color: "secondary.main", zIndex: (theme) => theme.zIndex.drawer + 1 }}
  >
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      sx={{
        margin: "auto",
        height: "100%",
        width: "100%",
        alignItems: "center"
      }}
    >
      {title && content ? (
        <Box
          display="flex"
          gap="8px"
          flexDirection="column"
          alignItems="center"
          padding="24px"
          borderRadius="8px"
          sx={{ backgroundColor: "secondary.main", width: "25%" }}
        >
          <Box display="flex" flexDirection="row" gap="8px">
            <CircularProgress size={20} />
            <Typography variant="body1" sx={{ color: "fileInput.dark" }}>
              {title}
            </Typography>
          </Box>
          <Typography variant="body2" sx={{ fontSize: "12px" }}>
            {content}
          </Typography>
        </Box>
      ) : (
        <CircularProgress />
      )}
    </Box>
  </Backdrop>
)

export default Loader
