import React from "react"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const CircleCheckMark = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask id="path-1-inside-1_1348_1970" fill="white">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM11.0068 15.5529L17.0068 10.0529L15.9932 8.94713L10.5226 13.9619L8.03033 11.4697L6.96967 12.5303L9.96967 15.5303L10.4774 16.0381L11.0068 15.5529Z"
          />
        </mask>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM11.0068 15.5529L17.0068 10.0529L15.9932 8.94713L10.5226 13.9619L8.03033 11.4697L6.96967 12.5303L9.96967 15.5303L10.4774 16.0381L11.0068 15.5529Z"
          fill="currentColor"
        />
        <path
          d="M17.0068 10.0529L18.0204 11.1586L19.1261 10.145L18.1125 9.03928L17.0068 10.0529ZM11.0068 15.5529L12.0204 16.6586L12.0204 16.6586L11.0068 15.5529ZM15.9932 8.94713L17.0989 7.93355L16.0854 6.82782L14.9796 7.8414L15.9932 8.94713ZM10.5226 13.9619L9.4619 15.0226L10.4774 16.0381L11.5361 15.0676L10.5226 13.9619ZM8.03033 11.4697L9.09099 10.409L8.03033 9.34835L6.96967 10.409L8.03033 11.4697ZM6.96967 12.5303L5.90901 11.4697L4.84835 12.5303L5.90901 13.591L6.96967 12.5303ZM9.96967 15.5303L8.90901 16.591L8.90901 16.591L9.96967 15.5303ZM10.4774 16.0381L9.41678 17.0988L10.4323 18.1143L11.491 17.1438L10.4774 16.0381ZM19.5 12C19.5 16.1421 16.1421 19.5 12 19.5V22.5C17.799 22.5 22.5 17.799 22.5 12H19.5ZM12 4.5C16.1421 4.5 19.5 7.85786 19.5 12H22.5C22.5 6.20101 17.799 1.5 12 1.5V4.5ZM4.5 12C4.5 7.85786 7.85786 4.5 12 4.5V1.5C6.20101 1.5 1.5 6.20101 1.5 12H4.5ZM12 19.5C7.85786 19.5 4.5 16.1421 4.5 12H1.5C1.5 17.799 6.20101 22.5 12 22.5V19.5ZM15.9932 8.94713L9.99321 14.4471L12.0204 16.6586L18.0204 11.1586L15.9932 8.94713ZM14.8875 9.96072L15.9011 11.0665L18.1125 9.03928L17.0989 7.93355L14.8875 9.96072ZM11.5361 15.0676L17.0068 10.0529L14.9796 7.8414L9.50897 12.8562L11.5361 15.0676ZM6.96967 12.5303L9.4619 15.0226L11.5832 12.9012L9.09099 10.409L6.96967 12.5303ZM8.03033 13.591L9.09099 12.5303L6.96967 10.409L5.90901 11.4697L8.03033 13.591ZM11.0303 14.4697L8.03033 11.4697L5.90901 13.591L8.90901 16.591L11.0303 14.4697ZM11.5381 14.9774L11.0303 14.4697L8.90901 16.591L9.41678 17.0988L11.5381 14.9774ZM9.99321 14.4471L9.46386 14.9324L11.491 17.1438L12.0204 16.6586L9.99321 14.4471Z"
          fill="currentColor"
          mask="url(#path-1-inside-1_1348_1970)"
        />
      </svg>
    </SvgIcon>
  )
}

export default CircleCheckMark
