import React from "react"
import { Box } from "@mui/material"

interface Props {
  normal: React.ReactNode
  hover: React.ReactNode
  sx?: React.CSSProperties
  disabled?: boolean
}

const HoverIcon = ({ disabled = false, ...props }: Props) => {
  const [hovering, setHovering] = React.useState(false)

  return (
    <Box
      {...props}
      onMouseEnter={() => !disabled && setHovering(true)}
      onMouseLeave={() => !disabled && setHovering(false)}
    >
      {hovering ? props.hover : props.normal}
    </Box>
  )
}

export default HoverIcon
