/* eslint-disable indent */
import React, { useState } from "react"
import {
  TableRow,
  TableCell,
  Checkbox,
  Modal,
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableHead,
  TableContainer
} from "@mui/material"
import { useFormContext } from "react-hook-form"
import { ZonePlacement } from "../../../models/zoneplacement"
import { CampaignFormModel } from "../../../models/campaign"
import { updateZonePlacementSelection } from "../../../utils"
import { useFlags } from "launchdarkly-react-client-sdk"

interface Props {
  zonePlacement: ZonePlacement
  onSelectZone: (zone: ZonePlacement, isLastPlacement?: boolean) => void
  isSelected: boolean
  disabled?: boolean
  setOpenResetConfirmationModal: React.Dispatch<React.SetStateAction<boolean>>
  setTempPlacementId: React.Dispatch<React.SetStateAction<number | undefined>>
  isCampaignEnded?: boolean
  showPlacements?: boolean
}

const ZonePlacementDisplay = ({
  zonePlacement,
  onSelectZone,
  isSelected,
  disabled,
  setOpenResetConfirmationModal,
  setTempPlacementId,
  isCampaignEnded,
  showPlacements
}: Props) => {
  const { watch, setValue } = useFormContext<CampaignFormModel>()
  const selectedZonePlacements = watch("selectedZonePlacements") || []
  const selectedZones = watch("selectedZones") || []
  const activeStores = watch("activeStores")
  const file = watch("file")
  const { placementTargeting } = useFlags()
  const [isModalOpen, setModalOpen] = useState(false)

  const placementCount = zonePlacement.placements?.length || 0
  const selectedPlacementIds = React.useMemo(() => {
    const selection = selectedZonePlacements.find(
      (selection) => selection.zoneId === zonePlacement.id
    )
    return selection?.placementIds || []
  }, [selectedZonePlacements, zonePlacement?.id])

  const selectedCount = selectedPlacementIds.length
  const indeterminate = selectedCount > 0 && !isSelected

  const handleCheckboxChange = () => {
    if (!disabled && !isCampaignEnded) {
      onSelectZone(zonePlacement)
    }
  }

  const handlePlacementToggle = (placementId: number) => {
    const newSelectedIds = selectedPlacementIds.includes(placementId)
      ? selectedPlacementIds.filter((id) => id !== placementId)
      : [...selectedPlacementIds, placementId]

    const hasActiveStores = (activeStores?.length ?? 0) > 0
    const isLastPlacement = newSelectedIds.length === 0
    const isDeselecting = selectedPlacementIds.includes(placementId)

    if (isLastPlacement && (hasActiveStores || file?.asset)) {
      onSelectZone(zonePlacement, true)
      return
    }

    if (selectedPlacementIds.includes(placementId) && hasActiveStores) {
      setTempPlacementId(placementId)
      setOpenResetConfirmationModal(true)
      return
    }

    const newSelectedZonePlacements = updateZonePlacementSelection(
      selectedZonePlacements,
      zonePlacement.id,
      newSelectedIds
    ).filter((selection) => selection.placementIds.length > 0)

    setValue("selectedZonePlacements", newSelectedZonePlacements)

    if (isDeselecting) {
      const newSelectedZones = selectedZones.filter(
        (zone) => zone.id !== zonePlacement.id
      )
      setValue("selectedZones", newSelectedZones)
    } else if (
      newSelectedIds.length === (zonePlacement.placements?.length || 0)
    ) {
      setValue("selectedZones", [...selectedZones, zonePlacement])
    }
  }

  const handleOpenModal = (event: React.MouseEvent) => {
    event.stopPropagation() // Prevent the row click event from firing
    if ((isSelected || indeterminate) && placementCount !== 0) {
      setModalOpen(true)
    }
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  return (
    <>
      <TableRow
        sx={{
          height: "36px",
          backgroundColor: isSelected ? "secondary.contrastText" : "inherit"
        }}
        onClick={handleCheckboxChange}
      >
        <TableCell
          sx={{ padding: 0, opacity: disabled || isCampaignEnded ? 0.5 : 1 }}
        >
          <Checkbox
            checked={isSelected}
            indeterminate={indeterminate}
            sx={{
              color: "secondary.dark",
              height: "36px",
              width: "36px",
              borderRadius: "50%",
              "&.Mui-checked": {
                color: "primary.light"
              },
              "&.MuiCheckbox-indeterminate": {
                color: "primary.light"
              }
            }}
            disabled={disabled || isCampaignEnded}
          />
          {zonePlacement.name}
        </TableCell>
        <TableCell
          sx={{
            color: disabled || isCampaignEnded ? "disabled.main" : "inherit"
          }}
        >
          {zonePlacement.orientation}
        </TableCell>
        <TableCell
          align="right"
          sx={{
            paddingX: "16px",
            cursor:
              placementTargeting ||
              (showPlacements &&
                (isSelected || indeterminate) &&
                placementCount !== 0)
                ? "pointer"
                : "default",
            color:
              (placementTargeting || showPlacements) &&
              (isSelected || indeterminate) &&
              placementCount !== 0
                ? "primary.light"
                : "inherit",
            opacity: isCampaignEnded ? 0.5 : 1
          }}
          onClick={
            (placementTargeting || showPlacements) &&
            (isSelected || indeterminate) &&
            placementCount !== 0 &&
            !isCampaignEnded
              ? handleOpenModal
              : undefined
          }
        >
          {(placementTargeting || showPlacements) &&
            (isSelected || indeterminate) &&
            placementCount !== 0 &&
            `${selectedCount}/${placementCount}`}
        </TableCell>
      </TableRow>

      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Box
          sx={{
            backgroundColor: "secondary.main",
            padding: "24px",
            borderRadius: "12px",
            width: "640px",
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            maxHeight: "80vh",
            overflow: "hidden"
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            {zonePlacement.name}
          </Typography>
          <TableContainer
            sx={{
              border: "1px solid",
              borderColor: "secondary.light",
              borderRadius: "8px",
              overflowY: "auto",
              "& .MuiTableBody-root tr:last-child td": {
                borderBottom: "none"
              }
            }}
          >
            <Table
              sx={{
                "& .MuiTableCell-root": { padding: 0 }
              }}
            >
              <TableHead>
                <TableRow sx={{ height: "36px" }}>
                  <TableCell
                    sx={{
                      borderColor: "secondary.light"
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{ color: "fileInput.dark", paddingLeft: "10px" }}
                    >
                      Placement
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {zonePlacement?.placements?.map((placement) => (
                  <TableRow key={placement.id}>
                    <TableCell>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: selectedPlacementIds.includes(
                            placement.id
                          )
                            ? "secondary.contrastText"
                            : "inherit"
                        }}
                      >
                        <Checkbox
                          checked={selectedPlacementIds.includes(placement.id)}
                          onChange={() => handlePlacementToggle(placement.id)}
                          sx={{
                            color: "secondary.dark",
                            "&.Mui-checked": {
                              color: "primary.light"
                            }
                          }}
                        />
                        <Typography variant="body2">
                          {placement.name}
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button variant="outlined" onClick={handleCloseModal}>
            Close
          </Button>
        </Box>
      </Modal>
    </>
  )
}

export default ZonePlacementDisplay
