import React from "react"
import { Alert, Box, SxProps } from "@mui/material"
import CircleCheckMark from "../../icons/CircleCheckMark"

type SuccessBannerProps = {
  children: React.ReactNode
  sx?: SxProps
}

const SuccessBanner: React.FC<SuccessBannerProps> = ({ children, sx }) => {
  return (
    <Box justifyContent="center" alignItems="flex-start" sx={sx}>
      <Alert
        icon={
          <CircleCheckMark
            sx={{ width: "20px", height: "20px", color: "success.dark" }}
          />
        }
        severity="success"
        sx={{
          height: "auto",
          backgroundColor: "success.main",
          color: "success.light",
          borderRadius: "4px",
          alignItems: "flex-start"
        }}
      >
        {children}
      </Alert>
    </Box>
  )
}

export default SuccessBanner
