import React from "react"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const Calendar = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1320_1267)">
          <mask
            id="mask0_1320_1267"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
          >
            <mask id="path-1-inside-1_1320_1267" fill="white">
              <rect x="3" y="5" width="18" height="16" rx="1" />
            </mask>
            <rect
              x="3"
              y="5"
              width="18"
              height="16"
              rx="1"
              stroke="black"
              strokeWidth="3"
              mask="url(#path-1-inside-1_1320_1267)"
            />
            <path d="M7.75 6V3" stroke="black" strokeWidth="1.5" />
            <path d="M16.25 6V3" stroke="black" strokeWidth="1.5" />
            <rect x="4" y="6" width="16" height="5" fill="black" />
            <path d="M20 11.25H4" stroke="black" strokeWidth="1.5" />
          </mask>
          <g mask="url(#mask0_1320_1267)">
            <rect width="24" height="24" fill="#0C0C0D" />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_1320_1267">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  )
}

export default Calendar
