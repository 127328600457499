import React from "react"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const TriangleExlcamationFill = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 3L22 21H2L12 3ZM11.25 15V10H12.75V15H11.25ZM11.25 16.5V18.5H12.75V16.5H11.25Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  )
}

export default TriangleExlcamationFill
