import React from "react"
import { Link, Typography, Button } from "@mui/material"
import AssetGuidelinesModal from "../AssetGuidelinesModal"

type FileErrorProps = {
  name: string
  storeArea?: string
}

export const CampaignFetchError = () => {
  return (
    <Typography variant="body2" color="error">
      Something wrong happened, please refresh the page. If the problem
      persists, contact{" "}
      <Link href="mailto:support@grocerytv.com" color="error" underline="none">
        support@grocerytv.com
      </Link>
    </Typography>
  )
}

export const AssetTypeError = ({ name }: FileErrorProps) => {
  return (
    <Typography variant="body2" color="error">
      {`The file "${name}" is not a supported file type. Please only upload one of the supported file types ( .jpg, .png, .mp4)`}
    </Typography>
  )
}

export const VideoDurationError = ({ name }: FileErrorProps) => {
  const [openModal, setOpenModal] = React.useState(false)

  const handleModalOpen = () => {
    setOpenModal(true)
  }

  const handleModalClose = () => {
    setOpenModal(false)
  }
  return (
    <Typography variant="body2" color="error">
      The file “{name}” has a duration longer than we currently support. Please
      review{" "}
      <Button
        variant="text"
        onClick={handleModalOpen}
        sx={{
          verticalAlign: "inherit",
          color: "text.secondary",
          paddingX: "4px"
        }}
      >
        our asset guidelines for videos
      </Button>
      <AssetGuidelinesModal open={openModal} onClose={handleModalClose} />
      and then try your upload again.
    </Typography>
  )
}

export const VideoSizeError = ({ name }: FileErrorProps) => {
  return (
    <Typography variant="body2" color="error">
      The file “{name}” is too large. Please reduce it’s file size to 10mb or
      less.
    </Typography>
  )
}

export const AssetAspectError = ({ name }: FileErrorProps) => {
  const [openModal, setOpenModal] = React.useState(false)

  const handleModalOpen = () => {
    setOpenModal(true)
  }

  const handleModalClose = () => {
    setOpenModal(false)
  }
  return (
    <Typography variant="body2" color="error">
      The file “{name}” is not a supported aspect ratio Please review our
      <Button
        variant="text"
        onClick={handleModalOpen}
        sx={{
          verticalAlign: "inherit",
          color: "text.secondary",
          paddingX: "4px"
        }}
      >
        asset guidelines
      </Button>
      <AssetGuidelinesModal open={openModal} onClose={handleModalClose} />
      and then try your upload again.
    </Typography>
  )
}

export const ImageAspectError = ({ name }: FileErrorProps) => {
  const [openModal, setOpenModal] = React.useState(false)

  const handleModalOpen = () => {
    setOpenModal(true)
  }

  const handleModalClose = () => {
    setOpenModal(false)
  }
  return (
    <Typography variant="body2" color="error">
      The file “{name}” is not a supported aspect ratio Please review our
      <Button
        variant="text"
        onClick={handleModalOpen}
        sx={{
          verticalAlign: "inherit",
          color: "text.secondary",
          paddingX: "4px"
        }}
      >
        asset guidelines for images
      </Button>
      <AssetGuidelinesModal open={openModal} onClose={handleModalClose} /> and
      then try your upload again.
    </Typography>
  )
}

export const VideoAspectError = ({ name }: FileErrorProps) => {
  const [openModal, setOpenModal] = React.useState(false)

  const handleModalOpen = () => {
    setOpenModal(true)
  }

  const handleModalClose = () => {
    setOpenModal(false)
  }
  return (
    <Typography variant="body2" color="error">
      The file “{name}” is not a supported aspect ratio Please review our
      <Button
        variant="text"
        onClick={handleModalOpen}
        sx={{
          verticalAlign: "inherit",
          color: "text.secondary",
          paddingX: "4px"
        }}
      >
        asset guidelines for videos
      </Button>
      <AssetGuidelinesModal open={openModal} onClose={handleModalClose} /> and
      then try your upload again.
    </Typography>
  )
}

export const AssetAspectWithStoreError = ({ name }: FileErrorProps) => {
  const [openModal, setOpenModal] = React.useState(false)

  const handleModalOpen = () => {
    setOpenModal(true)
  }

  const handleModalClose = () => {
    setOpenModal(false)
  }
  return (
    <Typography variant="body2" color="error">
      The file “{name}” is not the correct aspect ratio for the selected store
      areas. Please review our
      <Button
        variant="text"
        onClick={handleModalOpen}
        sx={{
          verticalAlign: "inherit",
          color: "text.secondary",
          paddingX: "4px"
        }}
      >
        asset guidelines
      </Button>
      <AssetGuidelinesModal open={openModal} onClose={handleModalClose} /> and
      then try your upload again.
    </Typography>
  )
}

export const AssetResolutionError = ({ name }: FileErrorProps) => {
  const [openModal, setOpenModal] = React.useState(false)

  const handleModalOpen = () => {
    setOpenModal(true)
  }

  const handleModalClose = () => {
    setOpenModal(false)
  }

  return (
    <Typography variant="body2" color="error">
      The file “{name}” does not meet the minimum resolution requirement Please
      review our
      <Button
        variant="text"
        onClick={handleModalOpen}
        sx={{
          verticalAlign: "inherit",
          color: "text.secondary",
          paddingX: "4px"
        }}
      >
        asset guidelines
      </Button>
      <AssetGuidelinesModal open={openModal} onClose={handleModalClose} />
      and then try your upload again.
    </Typography>
  )
}

export const VideoResolutionError = ({ name }: FileErrorProps) => {
  const [openModal, setOpenModal] = React.useState(false)

  const handleModalOpen = () => {
    setOpenModal(true)
  }

  const handleModalClose = () => {
    setOpenModal(false)
  }

  return (
    <Typography variant="body2" color="error">
      The file “{name}” does not meet the minimum resolution requirement Please
      review our
      <Button
        variant="text"
        onClick={handleModalOpen}
        sx={{
          verticalAlign: "inherit",
          color: "text.secondary",
          paddingX: "4px"
        }}
      >
        asset guidelines for videos
      </Button>
      <AssetGuidelinesModal open={openModal} onClose={handleModalClose} /> and
      then try your upload again.
    </Typography>
  )
}

export const ImageResolutionError = ({ name }: FileErrorProps) => {
  const [openModal, setOpenModal] = React.useState(false)

  const handleModalOpen = () => {
    setOpenModal(true)
  }

  const handleModalClose = () => {
    setOpenModal(false)
  }

  return (
    <Typography variant="body2" color="error">
      The file “{name}” does not meet the minimum resolution requirement Please
      review our
      <Button
        variant="text"
        onClick={handleModalOpen}
        sx={{
          verticalAlign: "inherit",
          color: "text.secondary",
          paddingX: "4px"
        }}
      >
        asset guidelines for images
      </Button>
      <AssetGuidelinesModal open={openModal} onClose={handleModalClose} /> and
      then try your upload again.
    </Typography>
  )
}
