import React from "react"
import { Box, Typography, Button } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { gql, useMutation } from "urql"
import ResetPasswordForm from "../../login/ResetPasswordForm"
import Copyright from "../../login/Copyright"
import LogoDisplay from "../../login/LogoDisplay"
import ErrorBanner from "../../banners/ErrorBanner"
import SuccessBanner from "../../banners/SuccessBanner"
import ArrowLoopBack from "../../icons/ArrowLoopBack"

const resetPasswordQuery = gql`
  mutation RequestPasswordReset($email: String!) {
    requestPasswordReset(email: $email)
  }
`

type ResetStatus = "error" | "success" | "none"

const ResetPasswordScreen = () => {
  const [, resetPassword] = useMutation(resetPasswordQuery)

  const [resetStatus, setResetStatus] = React.useState<ResetStatus>("none")

  const navigate = useNavigate()

  const handleBackHome = () => {
    navigate("/")
  }

  const handleSubmit = async (email: string) => {
    try {
      const result = await resetPassword(
        { email },
        {
          fetchOptions: {
            credentials: "include",
            mode: "cors"
          }
        }
      )

      if (result.data?.requestPasswordReset) {
        setResetStatus("success")
      } else {
        console.error("Failed to send password reset email.")
        setResetStatus("error")
      }
    } catch (error) {
      console.error(error)
      setResetStatus("error")
    }
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%"
      }}
      display="flex"
      flexDirection="row"
    >
      <Box
        sx={{
          width: "45%",
          maxWidth: "960px",
          minWidth: "640px"
        }}
        display="flex"
        flexDirection="column"
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          flexGrow={1}
        >
          <Box
            gap="32px"
            sx={{
              maxWidth: "480px",
              width: "360px",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center"
            }}
          >
            <Box display="flex" alignItems="flex-start">
              <Button
                startIcon={<ArrowLoopBack sx={{ color: "text.primary" }} />}
                onClick={handleBackHome}
              >
                <Typography>Back</Typography>
              </Button>
            </Box>
            {resetStatus === "error" && (
              <ErrorBanner>
                <Typography color="error" variant="body2">
                  We were not able to reset your password. If this error
                  persists, please contact our support.
                </Typography>
              </ErrorBanner>
            )}
            {resetStatus === "success" && (
              <SuccessBanner>
                <Typography color="success" variant="body2">
                  Please check your email for instructions on resetting your
                  password.
                </Typography>
              </SuccessBanner>
            )}
            <ResetPasswordForm onSubmit={handleSubmit} />
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="row"
          marginBottom="24px"
        >
          <Copyright />
        </Box>
      </Box>
      <Box display="flex" flexGrow={1}>
        <LogoDisplay />
      </Box>
    </Box>
  )
}

export default ResetPasswordScreen
