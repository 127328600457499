import React from "react"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const PauseFill = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5 5.5C5 5.22386 5.22386 5 5.5 5H9.5C9.77614 5 10 5.22386 10 5.5V18.5C10 18.7761 9.77614 19 9.5 19H5.5C5.22386 19 5 18.7761 5 18.5V5.5Z"
          fill="black"
        />
        <path
          d="M13 5.5C13 5.22386 13.2239 5 13.5 5H17.5C17.7761 5 18 5.22386 18 5.5V18.5C18 18.7761 17.7761 19 17.5 19H13.5C13.2239 19 13 18.7761 13 18.5V5.5Z"
          fill="black"
        />
      </svg>
    </SvgIcon>
  )
}

export default PauseFill
