import React from "react"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const CautionFill = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1366_1430)">
          <mask
            id="mask0_1366_1430"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM11.25 13.5V7H12.75V13.5H11.25ZM11.25 15V17H12.75V15H11.25Z"
              fill="currentColor"
            />
          </mask>
          <g mask="url(#mask0_1366_1430)">
            <rect width="24" height="24" fill="currentColor" />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_1366_1430">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  )
}

export default CautionFill
