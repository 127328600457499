import React from "react"
import { Typography, Modal, Button, Box, ButtonProps } from "@mui/material"

type ModalProps = {
  open: boolean
  onClose?: () => void
  title: string | React.ReactNode
  content: string | React.ReactNode
  cancelButtonText: string
  confirmButtonText: string
  confirmButtonVariant: ButtonProps["color"]
  onCancel?: () => void
  onConfirm?: () => void
  width?: string
}

const ConfirmationModal = ({
  open,
  title,
  content,
  cancelButtonText,
  confirmButtonText,
  confirmButtonVariant,
  onCancel,
  onConfirm,
  width = "448px"
}: ModalProps) => {
  return (
    <Modal open={open}>
      <Box
        display="flex"
        flexDirection="column"
        gap="16px"
        width={width}
        sx={{
          padding: "24px",
          bgcolor: "white",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          borderRadius: "12px",
          outline: "none"
        }}
      >
        {typeof title === "string" ? (
          <Typography
            variant="body1"
            sx={{ color: "fileInput.dark", fontWeight: 600 }}
          >
            {title}
          </Typography>
        ) : (
          title
        )}

        {typeof content === "string" ? (
          <Typography variant="body2">{content}</Typography>
        ) : (
          content
        )}
        <Box
          display="flex"
          marginTop="16px"
          gap="12px"
          justifyContent="flex-end"
          height="32px"
        >
          <Button variant="outlined" onClick={onCancel}>
            {cancelButtonText}
          </Button>
          <Button
            variant="contained"
            sx={{ boxShadow: "none" }}
            color={confirmButtonVariant}
            onClick={onConfirm}
          >
            {confirmButtonText}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default ConfirmationModal
