import React from "react"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const ArrowLoopBack = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.5 5.5L4.5 8.5M4.5 8.5L7.5 11.5M4.5 8.5H15.25C17.5972 8.5 19.5 10.4028 19.5 12.75V12.75C19.5 15.0972 17.5972 17 15.25 17H8.5"
          stroke="#0C0C0D"
          strokeWidth={1.5}
          strokeLinecap="square"
        />
      </svg>
    </SvgIcon>
  )
}

export default ArrowLoopBack
