import React, { useEffect } from "react"
import {
  Box,
  CircularProgress,
  CssBaseline,
  ThemeProvider
} from "@mui/material"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import theme from "./theme"
import {
  cacheExchange,
  fetchExchange,
  Provider,
  createClient,
  mapExchange
} from "urql"
import * as Sentry from "@sentry/react"
import * as amplitude from "@amplitude/analytics-browser"
import LoginScreen from "./screens/LoginScreen"
import ResetPasswordScreen from "./screens/ResetPasswordScreen"
import CampaignList from "./screens/CampaignList"
import UserAccount from "./screens/UserAccount"
import EditAccount from "./screens/EditAccount"
import CustomPropertyList from "./screens/CustomPropertyList"
import ManageCustomProperty from "./screens/ManageCustomProperty"
import axios from "axios"
import WelcomeScreen from "./screens/Welcome"
import { useLDClient, useFlags } from "launchdarkly-react-client-sdk"

const axiosDefaults = {}
export const http = axios.create(axiosDefaults)

function App() {
  const [showLogin, setShowLogin] = React.useState<boolean>(false)
  const [firstLoad, setFirstLoad] = React.useState<boolean>(true)

  const [userId, setUserId] = React.useState<number | null>(null)

  const { storeProperties } = useFlags()

  const ldClient = useLDClient()
  const checkAuth = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_REST_PLATFORM_API_PATH}/v2/auth/me`,
        {
          withCredentials: true
        }
      )

      if (response.status === 200) {
        const { name, email, company, id, roles } = response.data
        localStorage.setItem("companyName", company?.name)
        ldClient?.identify({
          kind: "user",
          key: id,
          email: email,
          company_id: company?.id,
          role: roles[0]
        })

        setUserId(id)

        if (process.env.REACT_APP_AMPLITUDE_API_KEY) {
          amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY, {
            defaultTracking: false
          })

          const identifyEvent = new amplitude.Identify()
          identifyEvent
            .set("name", name)
            .set("email", email)
            .set("account", company?.name)

          amplitude.identify(identifyEvent)

          amplitude.setUserId(id)
          setUserId(id)
        } else {
          console.error("Amplitude API key is missing.")
        }

        return true
      } else {
        ldClient?.identify({ kind: "user", key: "anonymous" })
        return false
      }
    } catch (error) {
      return false
    }
  }

  useEffect(() => {
    const checkIfAuthenticated = async () => {
      const auth = await checkAuth()
      setFirstLoad(false)
      setShowLogin(!auth)
    }
    checkIfAuthenticated()
  }, [])

  http.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401 || error.response.status === 403) {
        localStorage.clear()
        setShowLogin(true)
      }
      return Promise.reject(error)
    }
  )

  const networkClient = createClient({
    url: process.env.REACT_APP_GRAPH_QL_PATH as string,
    exchanges: [
      cacheExchange,
      mapExchange({
        onError: (error) => {
          const isAuthError = error.graphQLErrors.some(
            (e) => e.extensions?.code === "FORBIDDEN"
          )
          if (isAuthError) {
            const auth = checkAuth()
            if (!auth) {
              localStorage.clear()
              setShowLogin(true)
            }
          }
        }
      }),
      fetchExchange
    ],
    fetchOptions: {
      credentials: "include"
    }
  })

  if (firstLoad) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <CircularProgress />
        </Box>
      </ThemeProvider>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
        <CssBaseline />
        <Provider value={networkClient}>
          <BrowserRouter>
            <Routes>
              <Route path="/welcome" element={<WelcomeScreen />} />
              {showLogin && (
                <>
                  <Route
                    path="/reset_password"
                    element={<ResetPasswordScreen />}
                  />
                  <Route path="/*" element={<LoginScreen />} />
                </>
              )}
              {!showLogin && (
                <>
                  <Route path="/*" element={<CampaignList />} />
                  <Route path="/account" element={<UserAccount />} />
                  {userId && (
                    <Route
                      path="/account/edit"
                      element={<EditAccount userId={userId} />}
                    />
                  )}
                  {storeProperties && (
                    <>
                      <Route
                        path="/customproperty"
                        element={<CustomPropertyList />}
                      />
                      <Route
                        path="/customproperty/create"
                        element={<ManageCustomProperty />}
                      />
                      <Route
                        path="/customproperty/edit/:id"
                        element={<ManageCustomProperty />}
                      />
                    </>
                  )}
                </>
              )}
            </Routes>
          </BrowserRouter>
        </Provider>
      </Sentry.ErrorBoundary>
    </ThemeProvider>
  )
}

export default App
