import React from "react"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const ArrowRight = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1302_77232)">
          <mask
            id="mask0_1302_77232"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
          >
            <path
              d="M13.863 18L20 12M20 12L13.863 6M20 12H4"
              stroke="#0C0C0D"
              strokeWidth="1.5"
              strokeLinecap="square"
            />
          </mask>
          <g mask="url(#mask0_1302_77232)">
            <rect width="24" height="24" fill="#0C0C0D" />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_1302_77232">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  )
}

export default ArrowRight
