import React from "react"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const InfoIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_3661_14139)">
          <mask
            id="mask0_3661_14139"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="16"
            height="16"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8ZM7.25 6V4.66667H8.75V6H7.25ZM7.25 7L7.25 11.3333H8.75L8.75 7H7.25Z"
              fill="black"
            />
          </mask>
          <g mask="url(#mask0_3661_14139)">
            <rect width="16" height="16" fill="currentColor" />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_3661_14139">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  )
}

export default InfoIcon
