import React from "react"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const ArrowBack = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="11"
        height="18"
        viewBox="0 0 11 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 16L2 9L9 2"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="square"
        />
      </svg>
    </SvgIcon>
  )
}

export default ArrowBack
