import React from "react"
import ErrorBanner from "../banners/ErrorBanner"

const ShowCookieWarning = () => {
  const [showWarning, setShowWarning] = React.useState(false)

  React.useEffect(() => {
    if (!navigator.cookieEnabled) {
      setShowWarning(true)
    }
  }, [])

  if (!showWarning) {
    return null
  }

  return (
    <ErrorBanner data-testid="cookie-error">
      Your browser doesn’t accept cookies. Please enable cookies in your browser
      settings.
    </ErrorBanner>
  )
}

export default ShowCookieWarning
