import React from "react"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const EyeClosed = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="22"
        height="10"
        viewBox="0 0 22 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20 2C20 2 17.1941 6.66667 11 6.66667C4.80588 6.66667 2 2 2 2M3.58824 4.33333L2 6.08333M18.4118 4.33333L20 6.08333M7.82353 6.66667L7.29412 9M14.1765 6.66667L14.7059 9"
          stroke="black"
          strokeWidth={1.5}
          strokeLinecap="square"
        />
      </svg>
    </SvgIcon>
  )
}

export default EyeClosed
