import React from "react"
import {
  Typography,
  Avatar,
  Box,
  Button,
  List,
  ListItem,
  Divider
} from "@mui/material"
import { useNavigate, useLocation } from "react-router-dom"
import { useQuery, gql } from "urql"
import { getUserId, getInitials, getFirstAndLastName } from "../../../utils"
import ArrowLoopBack from "../../icons/ArrowLoopBack"
import NavBar from "../../navbar"
import ErrorBanner from "../../banners/ErrorBanner"
import SuccessBanner from "../../banners/SuccessBanner"

const CURRENT_USER_QUERY = gql`
  query getUser($id: Int!) {
    userDetails(id: $id) {
      companiesId
      email
      id
      name
      phone
    }
  }
`

const UserAccount = () => {
  const currentUserId = getUserId()
  const navigate = useNavigate()
  const location = useLocation()
  const [isSaved, setIsSaved] = React.useState(location.state?.saved)

  const [{ data, error }] = useQuery({
    query: CURRENT_USER_QUERY,
    variables: {
      id: currentUserId
    }
  })

  React.useEffect(() => {
    if (location.state?.saved) {
      navigate(location.pathname, {
        state: { ...location.state, saved: false }
      })
      const timer = setTimeout(() => {
        setIsSaved(false)
      }, 3000)
      return () => clearTimeout(timer)
    }
  }, [isSaved])

  const userInitial = getInitials(data?.userDetails?.name)
  const { firstName, lastName } = getFirstAndLastName(data?.userDetails?.name)

  const handleBack = () => {
    if (location.state?.fromEditPage) {
      navigate("/")
    } else {
      navigate(-1)
    }
  }

  if (error) {
    return (
      <Box>
        <ErrorBanner>
          <Typography variant="body2" color="error">
            Something happened, try refreshing the page. If the issue persists,
            contact our support email for help.
          </Typography>
        </ErrorBanner>
      </Box>
    )
  }

  return (
    <Box sx={{ overflow: "hidden" }}>
      <NavBar />
      <Box
        display="flex"
        justifyContent="flex-start"
        height="101px"
        alignItems="center"
        marginTop="64px"
        marginX="10%"
      >
        <Box display="flex" width="58%" justifyContent="space-between">
          <Button
            variant="text"
            onClick={handleBack}
            sx={{ color: "text.primary", fontWeight: 600 }}
          >
            <ArrowLoopBack
              sx={{ color: "text.primary", width: "18px", height: "18px" }}
            />
            Back
          </Button>

          {isSaved && (
            <SuccessBanner>
              <Typography variant="body1" color="success">
                Changes saved
              </Typography>
            </SuccessBanner>
          )}
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        gap="48px"
      >
        <Avatar
          sx={{
            height: "72px",
            width: "72px",
            fontSize: "24px",
            fontWeight: 600,
            bgcolor: "avatar.main"
          }}
        >
          {userInitial}
        </Avatar>
        <Box
          display="flex"
          justifyContent="space-between"
          width="480px"
          alignItems="center"
        >
          <Typography variant="body1" fontSize="18px">
            Account Information
          </Typography>
          <Button
            variant="outlined"
            onClick={() => navigate("/account/edit")}
            sx={{ height: "32px", fontWeight: 600 }}
          >
            Edit
          </Button>
        </Box>

        <List
          sx={{
            width: "480px",
            "& .MuiListItemText-root": { fontSize: "14px" }
          }}
        >
          <ListItem disablePadding>
            <Box
              display="flex"
              justifyContent="space-between"
              width="100%"
              paddingY="12px"
            >
              <Typography variant="body2" sx={{ color: "secondary.dark" }}>
                First name
              </Typography>
              <Box display="flex" justifyContent="flex-start" width="372px">
                <Typography variant="body2">{firstName}</Typography>
              </Box>
            </Box>
          </ListItem>
          <Divider />
          <ListItem disablePadding>
            <Box
              display="flex"
              justifyContent="space-between"
              width="100%"
              paddingY="12px"
            >
              <Typography variant="body2" sx={{ color: "secondary.dark" }}>
                Last name
              </Typography>
              <Box display="flex" justifyContent="flex-start" width="372px">
                <Typography variant="body2">{lastName}</Typography>
              </Box>
            </Box>
          </ListItem>
          <Divider />
          <ListItem disablePadding>
            <Box
              display="flex"
              justifyContent="space-between"
              width="100%"
              paddingY="12px"
            >
              <Typography variant="body2" sx={{ color: "secondary.dark" }}>
                Email
              </Typography>
              <Box display="flex" justifyContent="flex-start" width="372px">
                <Typography variant="body2">
                  {data?.userDetails?.email}
                </Typography>
              </Box>
            </Box>
          </ListItem>
          <Divider />
          <ListItem disablePadding>
            <Box
              display="flex"
              justifyContent="space-between"
              width="100%"
              paddingY="12px"
            >
              <Typography variant="body2" sx={{ color: "secondary.dark" }}>
                Password
              </Typography>
              <Box display="flex" justifyContent="space-between" width="372px">
                <Typography variant="body2">••••••••••••••••••</Typography>
                <Button
                  variant="text"
                  sx={{ color: "text.secondary", fontWeight: 600, paddingY: 0 }}
                >
                  Reset Password
                </Button>
              </Box>
            </Box>
          </ListItem>
          <Divider />
          <ListItem disablePadding>
            <Box
              display="flex"
              justifyContent="space-between"
              width="100%"
              paddingY="12px"
            >
              <Typography variant="body2" sx={{ color: "secondary.dark" }}>
                Phone number
              </Typography>
              <Box display="flex" justifyContent="flex-start" width="372px">
                <Typography variant="body2">
                  {data?.userDetails?.phone}
                </Typography>
              </Box>
            </Box>
          </ListItem>
        </List>
      </Box>
    </Box>
  )
}

export default UserAccount
