import React from "react"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const CircleXFill = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM7.93414 8.9948L10.9393 12L7.93414 15.0052L8.9948 16.0659L12 13.0607L15.0052 16.0659L16.0659 15.0052L13.0607 12L16.0659 8.9948L15.0052 7.93414L12 10.9393L8.9948 7.93414L7.93414 8.9948Z"
          fill="black"
        />
      </svg>
    </SvgIcon>
  )
}

export default CircleXFill
