import React from "react"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const CheckMark = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M5 12L10 17L20 7" stroke="currentColor" strokeWidth="1.5" />
      </svg>
    </SvgIcon>
  )
}

export default CheckMark
