import React, { useEffect, useState } from "react"
import { Pagination } from "../../../models/pagination"
import { Box, Button, TextField, Typography } from "@mui/material"
import { makeStyles } from "@material-ui/styles"
import ArrowBack from "../../icons/ArrowBack"
import ArrowForward from "../../icons/ArrowForward"

interface Props {
  pagination: Pagination
  onPageChange: (page: number) => void
}

const useStyles = makeStyles({
  input: {
    width: "40px",
    height: "24px",
    margin: "0px",
    textAlign: "center",
    fontSize: "12px"
  }
})

const PaginationBar = (props: Props) => {
  const classes = useStyles()

  const [enteredPageNumber, setEnteredPageNumber] = useState<string>(
    "" + props.pagination.page
  )

  useEffect(() => {
    const totalPages = Math.ceil(props.pagination.total / 100)

    if (props.pagination.total === 0) {
      setEnteredPageNumber("0")
    } else if (props.pagination.page > totalPages) {
      props.onPageChange(Math.max(totalPages, 1))
    } else {
      setEnteredPageNumber("" + props.pagination.page)
    }
  }, [props.pagination.page, props.pagination.total])

  const handleUserEnteredPageNumber = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEnteredPageNumber(e.target.value)
    const pageNumber = Number(e.target.value)
    if (
      pageNumber > 0 &&
      pageNumber <= props.pagination.total / props.pagination.pageSize
    ) {
      props.onPageChange(pageNumber)
    }
  }

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        padding: "12px",
        position: "sticky",
        bottom: "0px",
        backgroundColor: "#FFFFFF"
      }}
    >
      <Box display="flex" flexDirection="row" gap="12px">
        <Typography
          variant="body2"
          sx={{
            fontWeight: "500"
          }}
        >
          Total: {props.pagination.total}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: "#D9D9D9"
          }}
        >
          |
        </Typography>
        <Typography
          variant="body2"
          sx={{
            fontWeight: "600"
          }}
        >
          {props.pagination.pageSize} per page
        </Typography>
      </Box>
      <Box display="flex" flexDirection="row" gap="8px" alignItems="center">
        <TextField
          InputProps={{
            className: classes.input
          }}
          onChange={handleUserEnteredPageNumber}
          onBlur={() => setEnteredPageNumber("" + props.pagination.page)}
          variant="outlined"
          disabled={
            Math.ceil(props.pagination.total / props.pagination.pageSize) <= 1
          }
          value={enteredPageNumber}
          sx={{
            "& .MuiOutlinedInput-input": {
              padding: "1px !important",
              width: "40px",
              height: "24px",
              margin: "0px",
              textAlign: "center",
              fontSize: "12px"
            }
          }}
        />
        <Typography
          variant="body2"
          sx={{
            fontWeight: "600"
          }}
        >
          of {Math.ceil(props.pagination.total / props.pagination.pageSize)}{" "}
          {props.pagination.total / props.pagination.pageSize > 1
            ? "pages"
            : "page"}
        </Typography>
        <Box display="flex" flexDirection="row" gap="4px" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            sx={{
              height: "24px",
              width: "24px",
              maxWdith: "24px",
              minWidth: "24px"
            }}
            disabled={props.pagination.page === 1}
            onClick={() => props.onPageChange(props.pagination.page - 1)}
          >
            <ArrowBack
              sx={{
                padding: 0,
                margin: 0,
                height: "12px"
              }}
            />
          </Button>
          <Button
            variant="outlined"
            size="small"
            sx={{
              height: "24px",
              width: "24px",
              maxWdith: "24px",
              minWidth: "24px",
              padding: 0
            }}
            disabled={
              props.pagination.page >=
              Math.ceil(props.pagination.total / props.pagination.pageSize)
            }
            onClick={() => props.onPageChange(props.pagination.page + 1)}
          >
            <ArrowForward
              sx={{
                padding: 0,
                margin: 0,
                height: "12px"
              }}
            />
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default PaginationBar
