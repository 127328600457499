import React from "react"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const VideoOutline = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_1945_9462"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="20"
          height="20"
        >
          <path
            d="M2.41675 4.33301C2.41675 4.19494 2.52868 4.08301 2.66675 4.08301H17.3334C17.4715 4.08301 17.5834 4.19494 17.5834 4.33301V15.6663C17.5834 15.8044 17.4715 15.9163 17.3334 15.9163H2.66675C2.52868 15.9163 2.41675 15.8044 2.41675 15.6663V4.33301Z"
            stroke="#414D59"
            strokeWidth="1.5"
          />
          <path
            d="M5 4.16699V7.08366M5 15.8337V12.917M5 7.08366H2.5M5 7.08366V10.0003M5 12.917H2.5M5 12.917V10.0003M5 10.0003H2.5"
            stroke="#414D59"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15 15.833V12.9163M15 4.16634V7.08301M15 12.9163H17.5M15 12.9163V9.99967M15 7.08301H17.5M15 7.08301V9.99967M15 9.99967H17.5"
            stroke="#414D59"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.875 11.3349V9.49776L10.8959 10.4163L8.875 11.3349Z"
            fill="#414D59"
            stroke="#414D59"
            strokeWidth="1.5"
            strokeLinejoin="round"
          />
        </mask>
        <g mask="url(#mask0_1945_9462)">
          <rect width="20" height="20" fill="currentColor" />
        </g>
      </svg>
    </SvgIcon>
  )
}

export default VideoOutline
