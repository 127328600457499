import React from "react"
import Box from "@mui/material/Box"

const LogoDisplay = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        padding: "0px",
        justifyContent: "center",
        display: {
          xs: "none",
          sm: "none",
          md: "none",
          lg: "flex",
          xl: "flex"
        }
      }}
    >
      <video
        style={{
          width: "100%",
          display: "block",
          height: "100%",
          objectFit: "cover",
          padding: "0px",
          margin: "0px",
          zIndex: 1
        }}
        muted
        autoPlay
        loop
        src="/bg-gradient.mp4"
      >
        <img
          style={{
            width: "100%",
            display: "block",
            height: "100%",
            objectFit: "cover",
            padding: "0px",
            margin: "0px"
          }}
          src="/sizzle_bg.svg"
          alt="Background image"
        />
      </video>
      <img
        style={{
          position: "absolute",
          zIndex: 2,
          top: "50%",
          width: "272px",
          margin: "0px auto",
          height: "48px"
        }}
        src="/grocery-tv.svg"
        alt="Grocery TV"
      />
    </Box>
  )
}

export default LogoDisplay
