import React from "react"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const CircleInfoFill = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM8.25 6V4H9.75V6H8.25ZM8.25 7.5V14H9.75V7.5H8.25Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  )
}

export default CircleInfoFill
