import React from "react"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const CloudUpload = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_386_50208"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="20"
          height="21"
        >
          <path
            d="M7.49935 13.8104H4.99935C2.91602 13.8104 1.66602 12.0136 1.66602 10.0833C1.66602 7.77049 3.84253 5.98212 6.09591 6.39481C6.76465 4.42078 8.63275 3 10.8327 3C13.5751 3 15.8406 5.29342 15.8326 8.02953C17.2461 8.23167 18.3327 9.44727 18.3327 10.9167C18.3327 12.5275 17.0827 13.8333 15.416 13.8333H12.4993M7.39437 10.6051L9.99951 8M9.99951 8L12.6045 10.6051M9.99951 8V18"
            stroke="#414D59"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </mask>
        <g mask="url(#mask0_386_50208)">
          <rect y="0.5" width="20" height="20" fill="currentColor" />
        </g>
      </svg>
    </SvgIcon>
  )
}

export default CloudUpload
