import React from "react"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const InfoOutlineIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_3661_13449)">
          <mask
            id="mask0_3661_13449"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="16"
            height="16"
          >
            <circle cx="8" cy="8" r="5.25" stroke="#0C0C0D" strokeWidth="1.5" />
            <path d="M8 4.66699L8 6.00033" stroke="black" strokeWidth="1.5" />
            <path d="M8 7L8 11.3333" stroke="black" strokeWidth="1.5" />
          </mask>
          <g mask="url(#mask0_3661_13449)">
            <rect width="16" height="16" fill="currentColor" />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_3661_13449">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  )
}

export default InfoOutlineIcon
