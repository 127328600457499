import React from "react"
import { Box, Typography } from "@mui/material"
import TargetGrid from "../../targetGrid"
import { NewCampaignStore, Status } from "../../../models/campaign"
import { ZonePlacement } from "../../../models/zoneplacement"
import { StoreBanner } from "src/models/storetarget"

interface PropertyValueApiReponse {
  id: number
  name: string
  is_editable: boolean
}

interface PropertyApiResponse {
  id: number
  name: string
  type: string
  values: PropertyValueApiReponse[]
}
interface Props {
  zonePlacementList?: ZonePlacement[]
  campaignStores?: NewCampaignStore[]
  campaignStatus?: Status
  isLoadingStores: boolean
  storePropertyList: PropertyApiResponse[] | undefined
  campaignBannerList?: StoreBanner[]
  campaignStateList?: string[]
  isBannerExpanded: boolean
  isStateExpanded: boolean
}

const TargetSelector = ({
  zonePlacementList,
  campaignStores,
  campaignStatus,
  isLoadingStores,
  storePropertyList,
  campaignBannerList,
  campaignStateList,
  isBannerExpanded,
  isStateExpanded
}: Props) => {
  if (!zonePlacementList) {
    return null
  }

  if (!campaignStores) {
    return null
  }

  const isCampaignEnded = campaignStatus === "Ended"

  return (
    <Box
      sx={{
        width: "100%",
        marginTop: "30px"
      }}
      data-cy="zone-placement-selector"
    >
      <Typography
        sx={{
          fontSize: "18px",
          opacity: isCampaignEnded ? 0.5 : 1
        }}
        fontSize={18}
        data-cy="targeting-selector"
      >
        Stores
      </Typography>
      <Box
        sx={{
          flex: "0 0 auto",
          gap: "19px",
          width: "100%"
        }}
      >
        <TargetGrid
          data={campaignStores}
          isLoadingStores={isLoadingStores}
          isCampaignEnded={isCampaignEnded}
          properties={storePropertyList}
          bannerList={campaignBannerList}
          stateList={campaignStateList}
          isBannerExpanded={isBannerExpanded}
          isStateExpanded={isStateExpanded}
        />
      </Box>
    </Box>
  )
}

export default TargetSelector
