import React from "react"
import { Box, Button } from "@mui/material"

type OverlayProps = {
  searchInput: string
  handleClearInput: () => void
}

const NoRowOverlay = ({ searchInput, handleClearInput }: OverlayProps) => {
  return (
    <Box
      height="100%"
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      {searchInput ? `No results matching "${searchInput}"` : "No results"}
      {searchInput && (
        <Button
          variant="text"
          onClick={handleClearInput}
          sx={{ color: "text.secondary" }}
        >
          Clear Search
        </Button>
      )}
    </Box>
  )
}

export default NoRowOverlay
