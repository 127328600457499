import React from "react"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const ImageOutline = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask id="path-1-inside-1_1447_1372" fill="white">
          <rect x="2" y="4" width="20" height="16" rx="1" />
        </mask>
        <rect
          x="2"
          y="4"
          width="20"
          height="16"
          rx="1"
          stroke="currentColor"
          strokeWidth="3"
          mask="url(#path-1-inside-1_1447_1372)"
        />
        <path
          d="M21 15L16.5 10L11 16L8 13.5L3.5 18.5"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <circle cx="7.5" cy="9.5" r="1.5" fill="currentColor" />
      </svg>
    </SvgIcon>
  )
}

export default ImageOutline
