/* eslint-disable indent */
import React from "react"
import { Box, Typography, Button } from "@mui/material"
import AssetGuidelinesModal from "../AssetGuidelinesModal"
import CircleInfoFill from "../../icons/CircleInfoFill"
import { useWatch } from "react-hook-form"
import { ZonePlacement } from "../../../models/zoneplacement"
import { getZoneRatio } from "../../../utils"

type CreativeSectionHeaderProps = {
  asset?: string
  durationList?: (number | undefined)[]
  campaignZonePlacementList?: ZonePlacement[]
  isCampaignEnded?: boolean
}

const assetOrientation = (
  selectedZones: ZonePlacement[],
  selectedZonePlacements: { zoneId: number }[],
  campaignZonePlacementList: ZonePlacement[] = []
) => {
  const ratio = getZoneRatio(
    selectedZones,
    selectedZonePlacements,
    campaignZonePlacementList
  )

  switch (ratio) {
    case "9:16":
      return {
        content: "portrait orientation that has a 9:16 aspect ratio.",
        modalName: "Portrait assets here."
      }
    case "16:9":
      return {
        content: "landscape orientation that has a 16:9 aspect ratio.",
        modalName: "Landscape assets here."
      }
  }
}

const CreativeSectionHeader = ({
  asset,
  durationList,
  campaignZonePlacementList,
  isCampaignEnded
}: CreativeSectionHeaderProps) => {
  const [openModal, setOpenModal] = React.useState(false)
  const selectedZones = useWatch({
    name: "selectedZones"
  })
  const selectedZonePlacements = useWatch({
    name: "selectedZonePlacements"
  })

  const handleModalOpen = () => {
    setOpenModal(true)
  }

  const handleModalClose = () => {
    setOpenModal(false)
  }

  return (
    <Box
      display="flex"
      flexDirection="row"
      width="100%"
      justifyContent="space-between"
      alignItems="center"
      sx={{ opacity: isCampaignEnded ? 0.5 : 1 }}
    >
      {asset ? (
        <Typography variant="body1" sx={{ fontSize: "18px" }}>
          Asset
        </Typography>
      ) : selectedZones?.length > 0 || selectedZonePlacements?.length > 0 ? (
        <Typography variant="body2">
          Upload an image or video asset in
          <Typography variant="body2" sx={{ paddingX: "4px" }} component="span">
            {
              assetOrientation(
                selectedZones,
                selectedZonePlacements,
                campaignZonePlacementList
              ).content
            }
          </Typography>
        </Typography>
      ) : (
        <Typography variant="body2">
          Select at least one
          <Typography
            variant="body2"
            sx={{ paddingX: "4px", color: "text.secondary", fontWeight: 600 }}
            component="span"
          >
            Zone
          </Typography>
          <Typography variant="body2" component="span">
            to see what type of creative asset is needed for this campaign
          </Typography>
        </Typography>
      )}
      <Button
        variant="text"
        startIcon={<CircleInfoFill sx={{ width: "16px", height: "16px" }} />}
        onClick={handleModalOpen}
        sx={{
          color: "text.secondary",
          padding: "6px 0",
          fontWeight: 600,
          opacity: isCampaignEnded ? 0.5 : 1
        }}
        disabled={
          (selectedZones?.length === 0 &&
            selectedZonePlacements?.length === 0) ||
          isCampaignEnded
        }
      >
        Asset Guidelines
      </Button>
      <AssetGuidelinesModal
        open={openModal}
        durationList={durationList}
        orientation={getZoneRatio(
          selectedZones,
          selectedZonePlacements,
          campaignZonePlacementList ?? []
        )}
        onClose={handleModalClose}
      />
    </Box>
  )
}

export default CreativeSectionHeader
