import React from "react"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const TrashFill = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20 7H4"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="square"
        />
        <path d="M6 22H18L19.5 6.25195L4.5 6.25203L6 22Z" fill="currentColor" />
        <path
          d="M15 7V3.25C15 3.11193 14.8881 3 14.75 3H9.25C9.11193 3 9 3.11193 9 3.25V7"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  )
}

export default TrashFill
