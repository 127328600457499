import React from "react"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const ArrowUp = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1302_77206)">
          <mask
            id="mask0_1302_77206"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
          >
            <path
              d="M6 10.137L12 4M12 4L18 10.137M12 4V20"
              stroke="#0C0C0D"
              strokeWidth="1.5"
              strokeLinecap="square"
            />
          </mask>
          <g mask="url(#mask0_1302_77206)">
            <rect width="24" height="24" fill="#0C0C0D" />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_1302_77206">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  )
}

export default ArrowUp
