import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import {
  Box,
  Typography,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Select,
  MenuItem,
  FormControl
} from "@mui/material"
import NavBar from "../../navbar"
import Loader from "../../Loader"
import ConfirmationModal from "../../campaigns/ConfirmationModal"
import { http } from "../../App"
import CircleAdd from "../../icons/CircleAdd"
import SettingOutlined from "../../icons/SettingOutlined"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import InfoOutlineIcon from "../../icons/InfoOutlineIcon"
import ArrowRight from "../../icons/ArrowRight"
import { NewCampaignStore, StoreProperty } from "../../../models/campaign"
import ArrowLeft from "../../icons/ArrowLeft"

interface PropertyValue {
  id: string
  name: string
  is_editable: boolean
}

interface PropertyApiResponse {
  id: number
  name: string
  type: string
  values: PropertyValue[]
}

const CustomPropertyList = () => {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [properties, setProperties] = useState<PropertyApiResponse[]>([])
  const [selectedProperty, setSelectedProperty] = useState<string | null>(null)
  const [stores, setStores] = useState<NewCampaignStore[]>([])
  const [storesRequiringValues, setStoresRequiringValues] = useState<
    NewCampaignStore[]
  >([])
  const [selectedPropertyValueId, setSelectedPropertyValueId] = useState<
    string | null
  >(null)
  const [selectedStoreId, setSelectedStoreId] = useState<number | null>(null)
  const [isAccordionOpen, setIsAccordionOpen] = useState(false)
  const [missingPropertyMapping, setMissingPropertyMapping] = useState<{
    [key: string]: { id: string; name: string }
  }>({})
  const [openConfirmationModal, setOpenConfirmationModal] =
    React.useState(false)

  const fetchData = async () => {
    try {
      setLoading(true)
      const [
        propertiesResponse,
        storesRequiringValuesResponse,
        allStoresResponse
      ] = await Promise.all([
        http.get(`${process.env.REACT_APP_REST_API_PATH}/store_properties`, {
          withCredentials: true
        }),
        http.get(
          `${process.env.REACT_APP_REST_API_PATH}/stores/requiring_property_values`,
          {
            withCredentials: true
          }
        ),
        http.get(`${process.env.REACT_APP_REST_API_PATH}/stores`, {
          withCredentials: true
        })
      ])

      const fetchedProperties = propertiesResponse.data
      const fetchedStoresRequiringValues = storesRequiringValuesResponse.data
      const fetchedAllStores = allStoresResponse.data

      // Set properties state
      if (fetchedProperties.length > 0) {
        fetchedProperties.forEach((property: PropertyApiResponse) => {
          property.values.sort((a, b) => {
            if (a.name === "Not Applicable") return 1
            if (b.name === "Not Applicable") return -1
            return 0
          })
        })

        setProperties(fetchedProperties)
        setSelectedProperty(fetchedProperties[0].name)

        setSelectedPropertyValueId(fetchedProperties[0].values[0].id)
      }

      setStores(fetchedAllStores)
      setStoresRequiringValues(fetchedStoresRequiringValues)

      // Automatically select the first store if available
      if (fetchedStoresRequiringValues.length > 0) {
        setSelectedStoreId(fetchedStoresRequiringValues[0].id)

        const selectedStore = fetchedStoresRequiringValues[0]
        const storePropertyNames = selectedStore.properties.map(
          (prop: StoreProperty) => prop.property_name
        )

        const missingProperties: PropertyApiResponse[] =
          fetchedProperties.filter((prop: PropertyApiResponse) => {
            return !storePropertyNames.includes(prop.name)
          })

        const initialMissingPropertyMapping = missingProperties.reduce<{
          [key: string]: { id: string; name: string }
        }>((acc, prop: PropertyApiResponse) => {
          acc[prop.name] = { id: "", name: "" }
          return acc
        }, {})

        setMissingPropertyMapping(initialMissingPropertyMapping)
      }
    } catch (error) {
      console.error("Failed to fetch data:", error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handlePropertySwitch = (newProperty: string) => {
    const selected = properties.find(
      (property) => property.name === newProperty
    )

    setSelectedProperty(newProperty)

    if (selected) {
      setSelectedPropertyValueId(
        selected.values.length > 0 ? selected.values[0].id : null
      )
    }
  }

  const handlePropertyValueSwitch = (newPropertyValueId: string) => {
    setSelectedPropertyValueId(newPropertyValueId)
  }

  const getStoreCountForValue = (valueId: string) => {
    return stores.filter((store) =>
      store.properties.some((property) => property.value_id === valueId)
    ).length
  }

  const handleStoreSelection = (storeId: number) => {
    if (storeId !== selectedStoreId) {
      setSelectedStoreId(storeId)

      // Find the selected store
      const selectedStore = storesRequiringValues.find(
        (store) => store.id === storeId
      )

      if (selectedStore) {
        // Get the names of properties already present in the store
        const storePropertyNames = selectedStore.properties.map(
          (prop) => prop.property_name
        )

        // Find missing properties by comparing store's properties with fetched properties
        const missingProperties = properties.filter(
          (prop) => !storePropertyNames.includes(prop.name)
        )

        const initialValues = missingProperties.reduce<{
          [key: string]: { id: string; name: string }
        }>((acc, prop: PropertyApiResponse) => {
          acc[prop.name] = { id: "", name: "" }
          return acc
        }, {})

        setMissingPropertyMapping(initialValues)
      }
    }
  }

  const handleDropdownChange = (
    propertyName: string,
    valueId: string,
    valueName: string
  ) => {
    setMissingPropertyMapping((prevMapping) => ({
      ...prevMapping,
      [propertyName]: { id: valueId, name: valueName } // Store both value ID and name
    }))
  }

  const isSaveDisabled = Object.values(missingPropertyMapping).some(
    (value) => value.name === ""
  )

  const handleSaveClick = async () => {
    try {
      setLoading(true)
      console.log("Submitting missing properties: ", missingPropertyMapping)

      await Promise.all(
        Object.keys(missingPropertyMapping).map((propertyName) => {
          const selectedProperty = missingPropertyMapping[propertyName]
          return http.post(
            `${process.env.REACT_APP_REST_API_PATH}/stores/${selectedStoreId}/store_property_values/${selectedProperty.id}`,
            {},
            { withCredentials: true }
          )
        })
      )

      console.log(
        `Successfully submitted properties for store ${selectedStoreId}`
      )
      await fetchData()
    } catch (error) {
      console.error("Failed to submit properties: ", error)
    } finally {
      setLoading(false)
      setOpenConfirmationModal(false)
    }
  }

  const handleConfirmationModalClose = () => {
    setOpenConfirmationModal(false)
  }

  const handleEditProperty = () => {
    const selectedPropertyData = properties.find(
      (property) => property.name === selectedProperty
    )
    if (selectedPropertyData) {
      navigate(`/customproperty/edit/${selectedPropertyData.id}`)
    }
  }

  const totalContentCount = 6 + Object.keys(missingPropertyMapping).length * 2

  const modalTitle = (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
      <InfoOutlineIcon sx={{ height: "18px", color: "error.main" }} />
      <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
        <Typography
          variant="body2"
          sx={{ fontSize: "18px", fontWeight: 600, color: "fileInput.dark" }}
        >
          Are you sure?
        </Typography>
        <Typography
          variant="body2"
          sx={{ fontSize: "14px", color: "fileInput.dark" }}
        >
          <Box component="span" sx={{ fontWeight: "bold", marginRight: "2px" }}>
            {totalContentCount}
          </Box>
          pieces of content will now be
          <Box component="span" sx={{ fontWeight: "bold", marginX: "2px" }}>
            added
          </Box>
          to this store. Are you sure you want to continue?
        </Typography>
      </Box>
    </Box>
  )

  const selectedStore = storesRequiringValues.find(
    (store) => store.id === selectedStoreId
  )

  const modalContent = (
    <Box sx={{ display: "flex", gap: "32px", justifyContent: "space-between" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          width: "45%"
        }}
      >
        <Typography variant="body2" sx={{ color: "fileInput.dark" }}>
          Map stores
        </Typography>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "auto 1fr",
            gap: "12px",
            padding: "12px",
            border: "1px solid",
            borderColor: "secondary.light"
          }}
        >
          <Typography variant="body2">Banner</Typography>
          <Typography variant="body2" sx={{ textAlign: "right" }}>
            {selectedStore?.banner?.name ?? "N/A"}
          </Typography>

          <Typography variant="body2">Store</Typography>
          <Typography variant="body2" sx={{ textAlign: "right" }}>
            {selectedStore?.name ?? "N/A"}
          </Typography>

          <Typography variant="body2">Location</Typography>
          <Typography variant="body2" sx={{ textAlign: "right" }}>
            {selectedStore
              ? `${selectedStore?.address?.city}, ${selectedStore?.address}`
              : "N/A"}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ alignSelf: "center" }}>
        <ArrowRight />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          width: "45%"
        }}
      >
        <Typography variant="body2" sx={{ color: "fileInput.dark" }}>
          Property Value(s)
        </Typography>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "auto 1fr",
            gap: "12px",
            padding: "12px",
            border: "1px solid",
            borderColor: "secondary.light"
          }}
        >
          {Object.keys(missingPropertyMapping).map((key) => (
            <React.Fragment key={key}>
              <Typography variant="body2">
                {key.charAt(0).toUpperCase() + key.slice(1)}
              </Typography>
              <Typography variant="body2" sx={{ textAlign: "right" }}>
                {missingPropertyMapping[key].name ?? "N/A"}
              </Typography>
            </React.Fragment>
          ))}
        </Box>
      </Box>
    </Box>
  )

  return (
    <Box sx={{ overflow: "hidden", minHeight: "100vh" }}>
      <NavBar title="Custom Store Properties" />
      <Box
        display="flex"
        flexDirection="row"
        height="32px"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          width: "62%",
          padding: "88px 10% 0"
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer"
          }}
          onClick={() => navigate("/")}
        >
          <ArrowLeft
            sx={{
              color: "text.secondary",
              mr: "4px",
              width: "16px",
              height: "16px"
            }}
          />
          <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
            All Campaigns
          </Typography>
        </Box>
      </Box>
      {loading ? (
        <Loader active={true} title="Loading" content="Please wait..." />
      ) : (
        <>
          {storesRequiringValues && storesRequiringValues.length > 0 && (
            <Box
              sx={{
                padding: "120px 10% 0"
              }}
            >
              <Accordion
                disableGutters
                onChange={(event, isExpanded) => setIsAccordionOpen(isExpanded)}
                sx={{
                  backgroundColor: "info.main",
                  border: "1px solid ",
                  borderColor: "info.light",
                  boxShadow: "none",
                  overflow: "hidden",
                  color: "info.dark",
                  "&:first-of-type": {
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px"
                  },
                  "&:last-of-type": {
                    borderBottomLeftRadius: "8px",
                    borderBottomRightRadius: "8px"
                  }
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "info.dark" }} />}
                  aria-controls="panel1-content"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: "12px 16px",
                    backgroundColor: "info.main",
                    height: "72px",
                    "& .MuiAccordionSummary-content": {
                      justifyContent: "space-between"
                    }
                  }}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "8px" }}
                  >
                    <InfoOutlineIcon
                      sx={{ height: "18px", color: "info.dark" }}
                    />

                    <Typography variant="body2" sx={{ color: "info.dark" }}>
                      You have {storesRequiringValues.length} new stores to map
                    </Typography>
                  </Box>
                  {isAccordionOpen ? (
                    <Typography variant="body2" sx={{ color: "info.dark" }}>
                      Hide
                    </Typography>
                  ) : (
                    <Typography variant="body2" sx={{ color: "info.dark" }}>
                      Map Stores
                    </Typography>
                  )}
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    overflowY: "scroll"
                  }}
                >
                  <Box
                    sx={{
                      border: "1px solid",
                      height: "110px",
                      width: "60%",
                      overflowY: "hidden",
                      backgroundColor: "secondary.main",
                      borderColor: "secondary.light",
                      borderRadius: "8px",
                      display: "flex",
                      flexDirection: "column"
                    }}
                  >
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 1fr",
                        padding: "0 12px",
                        columnGap: "20px",
                        borderBottom: "1px solid",
                        borderColor: "secondary.light",
                        fontWeight: "bold",
                        height: "36px",
                        alignItems: "center"
                      }}
                    >
                      <Typography>Banner</Typography>
                      <Typography>Store</Typography>
                      <Typography>Location</Typography>
                    </Box>

                    <Box sx={{ overflowY: "scroll", height: "72px" }}>
                      {storesRequiringValues.map((store, index) => {
                        const isLastRow =
                          index === storesRequiringValues.length - 1
                        const isSelected = selectedStoreId === store.id
                        return (
                          <Box
                            key={index}
                            onClick={() => handleStoreSelection(store.id)}
                            sx={{
                              display: "grid",
                              gridTemplateColumns: "1fr 1fr 1fr",
                              columnGap: "20px",
                              paddingLeft: "12px",
                              borderBottom: "1px solid",
                              borderColor: "secondary.light",
                              height: "36px",
                              alignItems: "center",
                              backgroundColor: isSelected
                                ? "primary.dark"
                                : "inherit",
                              borderBottomLeftRadius:
                                isLastRow &&
                                storesRequiringValues.length > 1 &&
                                isSelected
                                  ? "8px"
                                  : "0px",
                              borderBottomRightRadius:
                                isLastRow &&
                                storesRequiringValues.length > 1 &&
                                isSelected
                                  ? "8px"
                                  : "0px",
                              cursor: "pointer"
                            }}
                          >
                            <Tooltip title={store?.banner?.name || "N/A"}>
                              <Typography
                                sx={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  color: isSelected ? "white" : "none"
                                }}
                              >
                                {store?.banner?.name || "N/A"}
                              </Typography>
                            </Tooltip>

                            <Tooltip title={store?.name}>
                              <Typography
                                sx={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  color: isSelected ? "white" : "none"
                                }}
                              >
                                {store?.name}
                              </Typography>
                            </Tooltip>

                            <Tooltip
                              title={
                                store?.address?.city && store?.address?.state
                                  ? `${store.address.city}, ${store.address.state}`
                                  : "N/A"
                              }
                            >
                              <Typography
                                sx={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  color: isSelected ? "white" : "none"
                                }}
                              >
                                {store?.address?.city && store?.address?.state
                                  ? `${store.address.city}, ${store.address.state}`
                                  : "N/A"}
                              </Typography>
                            </Tooltip>
                          </Box>
                        )
                      })}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      width: "30%",
                      maxHeight: "315px",
                      border: "1px solid",
                      backgroundColor: "secondary.main",
                      borderColor: "secondary.light",
                      borderRadius: "8px",
                      padding: "12px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "12px"
                    }}
                  >
                    <Typography variant="body2">Map Stores</Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "12px"
                      }}
                    >
                      {Object.keys(missingPropertyMapping).map(
                        (propertyName) => (
                          <FormControl key={propertyName} fullWidth>
                            <Typography
                              variant="body2"
                              sx={{ marginBottom: "4px" }}
                            >
                              {propertyName}
                            </Typography>
                            <Select
                              value={
                                missingPropertyMapping[propertyName]?.id || ""
                              }
                              onChange={(event) => {
                                const selectedProperty = properties
                                  .find((prop) => prop.name === propertyName)
                                  ?.values.find(
                                    (value) => value.id === event.target.value
                                  )

                                handleDropdownChange(
                                  propertyName,
                                  selectedProperty?.id || "",
                                  selectedProperty?.name || ""
                                )
                              }}
                              displayEmpty
                              renderValue={(selected) => {
                                const selectedProperty = properties
                                  .find((prop) => prop.name === propertyName)
                                  ?.values.find(
                                    (value) => value.id === selected
                                  )

                                return (
                                  selectedProperty?.name || "Select a value"
                                )
                              }}
                            >
                              {properties
                                .find((prop) => prop.name === propertyName)
                                ?.values.map((value) => (
                                  <MenuItem key={value.id} value={value.id}>
                                    {value.name}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        )
                      )}
                      <Button
                        variant="contained"
                        color="error"
                        fullWidth
                        disabled={isSaveDisabled}
                        onClick={() => setOpenConfirmationModal(true)}
                      >
                        Save
                      </Button>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          )}

          {properties && properties.length > 0 ? (
            <Box
              sx={{
                padding: "32px 10% ",
                display: "flex",
                flexDirection: "column",
                gap: "24px"
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="body2" sx={{ fontSize: "20px" }}>
                  Custom Store Properties
                </Typography>
                <Button
                  variant="contained"
                  onClick={() => {
                    navigate("/customproperty/create")
                  }}
                  sx={{
                    color: "secondary.main",
                    boxShadow: "none",
                    height: "36px"
                  }}
                >
                  + Create Custom Property
                </Button>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <ToggleButtonGroup
                  value={selectedProperty}
                  exclusive
                  onChange={(event, newButton) => {
                    if (newButton !== null) {
                      handlePropertySwitch(newButton)
                    }
                  }}
                  sx={{
                    display: "flex",
                    height: "36px",
                    gap: "12px",
                    "& .MuiTypography-root": {
                      fontWeight: 600,
                      textTransform: "capitalize"
                    },
                    "& .MuiToggleButtonGroup-grouped:not(:first-of-type)": {
                      border: "1px solid",
                      borderColor: "primary.contrastText",
                      borderRadius: "4px"
                    },
                    "& .MuiToggleButtonGroup-grouped:not(:last-of-type)": {
                      borderRadius: "4px"
                    },
                    "& .MuiToggleButtonGroup-grouped.Mui-selected": {
                      backgroundColor: "primary.dark",
                      color: "white",
                      "& .MuiTypography-root": {
                        color: "white"
                      },
                      "&:hover": {
                        backgroundColor: "primary.main"
                      }
                    }
                  }}
                >
                  {properties?.map((item, index) => (
                    <ToggleButton key={index} value={item.name}>
                      <Typography variant="body2">{item.name}</Typography>
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Box>

              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    textTransform: "capitalize"
                  }}
                >
                  {selectedProperty}
                </Typography>
                <Button
                  variant="outlined"
                  sx={{
                    height: "36px"
                  }}
                  onClick={handleEditProperty}
                >
                  <SettingOutlined
                    sx={{
                      height: "18px"
                    }}
                  />
                  <Typography variant="body2" sx={{ fontWeight: 600 }}>
                    Manage Property
                  </Typography>
                </Button>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between"
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    border: "1px solid",
                    borderColor: "secondary.light",
                    borderRadius: "8px",
                    width: "25%",
                    height: "240px"
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "0 12px",
                        borderBottom: "1px solid",
                        borderColor: "secondary.light"
                      }}
                    >
                      <Typography variant="body2" sx={{ lineHeight: "36px" }}>
                        Value
                      </Typography>
                      <Typography variant="body2" sx={{ lineHeight: "36px" }}>
                        # of stores
                      </Typography>
                    </Box>
                    <Box sx={{ maxHeight: "200px", overflowY: "auto" }}>
                      <ToggleButtonGroup
                        value={selectedPropertyValueId}
                        orientation="vertical"
                        exclusive
                        onChange={(event, newButton) => {
                          if (newButton !== null) {
                            handlePropertyValueSwitch(newButton)
                          }
                        }}
                        sx={{
                          display: "flex",

                          gap: "12px",
                          "& .MuiToggleButtonGroup-grouped": {
                            border: "0",
                            borderRadius: "0"
                          },
                          "& .MuiToggleButtonGroup-grouped.Mui-selected": {
                            backgroundColor: "primary.dark",
                            color: "white",
                            "& .MuiTypography-root": {
                              color: "white"
                            },
                            "&:hover": {
                              backgroundColor: "primary.main"
                            }
                          }
                        }}
                      >
                        {selectedProperty &&
                          properties
                            .find(
                              (property) => property.name === selectedProperty
                            )
                            ?.values.map((item, index) => (
                              <ToggleButton
                                key={index}
                                value={item.id}
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between"
                                }}
                              >
                                <Typography variant="body2">
                                  {item.name}
                                </Typography>
                                <Typography variant="body2">
                                  {getStoreCountForValue(item.id)}
                                </Typography>
                              </ToggleButton>
                            ))}
                      </ToggleButtonGroup>
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: "65%",
                    border: "1px solid",
                    borderColor: "secondary.light",
                    borderRadius: "8px"
                  }}
                >
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1.5fr 1.5fr 1.5fr 1fr",
                      padding: "0 12px",
                      borderBottom: "1px solid",
                      borderColor: "secondary.light",
                      fontWeight: "bold",
                      height: "36px",
                      alignItems: "center"
                    }}
                  >
                    <Typography>Banner</Typography>
                    <Typography>Store</Typography>
                    <Typography>Location</Typography>
                    <Typography>{selectedProperty}</Typography>
                  </Box>

                  <Box
                    sx={{
                      height: "360px",
                      overflowY: "scroll"
                    }}
                  >
                    {stores
                      .filter((store) =>
                        store.properties.some(
                          (property) =>
                            property.value_id === selectedPropertyValueId
                        )
                      )
                      .map((store, index) => (
                        <Box
                          key={index}
                          sx={{
                            display: "grid",
                            gridTemplateColumns: "1.5fr 1.5fr 1.5fr 1fr",
                            paddingLeft: "12px",
                            borderBottom: "1px solid",
                            borderColor: "secondary.light",
                            height: "36px",
                            alignItems: "center"
                          }}
                        >
                          <Tooltip title={store.banner.name || "N/A"}>
                            <Typography
                              sx={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis"
                              }}
                            >
                              {store.banner.name || "N/A"}
                            </Typography>
                          </Tooltip>

                          <Tooltip title={store.name}>
                            <Typography
                              sx={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis"
                              }}
                            >
                              {store.name}
                            </Typography>
                          </Tooltip>

                          <Tooltip
                            title={
                              store.address.city && store.address.state
                                ? `${store.address.city}, ${store.address.state}`
                                : "N/A"
                            }
                          >
                            <Typography
                              sx={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis"
                              }}
                            >
                              {store.address.city && store.address.state
                                ? `${store.address.city}, ${store.address.state}`
                                : "N/A"}
                            </Typography>
                          </Tooltip>

                          <Tooltip
                            title={
                              store.properties.find(
                                (property) =>
                                  property.property_name === selectedProperty &&
                                  property.value_id === selectedPropertyValueId
                              )?.value_name || "N/A"
                            }
                          >
                            <Typography
                              sx={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis"
                              }}
                            >
                              {store.properties.find(
                                (property) =>
                                  property.property_name === selectedProperty &&
                                  property.value_id === selectedPropertyValueId
                              )?.value_name || "N/A"}
                            </Typography>
                          </Tooltip>
                        </Box>
                      ))}
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                padding: "0 10%",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                height: "70vh"
              }}
            >
              <Box
                sx={{
                  padding: "40px",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                  height: "80%"
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "40px",
                    width: "398px"
                  }}
                >
                  <Box
                    sx={{
                      gap: "8px",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{ fontWeight: "500", fontSize: "20px" }}
                    >
                      Create a store property to get started
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ marginBottom: "30px", maxWidth: "600px" }}
                    >
                      Store properties are custom groups that allow you to map
                      content to your stores the way you want to.
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                      flexWrap: "wrap",
                      justifyContent: "center"
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "16px"
                      }}
                    >
                      <Button
                        variant="outlined"
                        sx={{
                          height: "36px",
                          borderRadius: "40px",
                          color: "text.primary"
                        }}
                      >
                        <CircleAdd sx={{ height: "18px" }} />
                        Region
                      </Button>
                      <Button
                        variant="outlined"
                        sx={{
                          height: "36px",
                          borderRadius: "40px",
                          color: "text.primary"
                        }}
                      >
                        <CircleAdd sx={{ height: "18px" }} />
                        District
                      </Button>
                      <Button
                        variant="outlined"
                        sx={{
                          height: "36px",
                          borderRadius: "40px",
                          color: "text.primary"
                        }}
                      >
                        <CircleAdd sx={{ height: "18px" }} />
                        Pharmacy
                      </Button>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "16px",
                        justifyContent: "center"
                      }}
                    >
                      <Button
                        variant="outlined"
                        sx={{
                          height: "36px",
                          borderRadius: "40px",
                          color: "text.primary"
                        }}
                      >
                        <CircleAdd sx={{ height: "18px" }} />
                        Superstore
                      </Button>
                      <Button
                        variant="outlined"
                        sx={{
                          height: "36px",
                          borderRadius: "40px",
                          color: "text.primary"
                        }}
                      >
                        <CircleAdd sx={{ height: "18px" }} />
                        Rewards
                      </Button>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      gap: "8px",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => {
                        navigate("/customproperty/create")
                      }}
                      sx={{
                        color: "secondary.main",
                        boxShadow: "none",
                        height: "36px"
                      }}
                    >
                      <CircleAdd sx={{ height: "18px" }} /> Create Custom
                      Property
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}

          <ConfirmationModal
            open={openConfirmationModal}
            title={modalTitle}
            content={modalContent}
            cancelButtonText="Cancel"
            confirmButtonText="Yes, map this store"
            confirmButtonVariant="error"
            onCancel={handleConfirmationModalClose}
            onConfirm={handleSaveClick}
            width="710px"
          />
        </>
      )}
    </Box>
  )
}

export default CustomPropertyList
