import React from "react"
import { Box, Button, Typography } from "@mui/material"
import CheckMark from "../../icons/CheckMark"
import ArrowForward from "../../icons/ArrowForward"
import ArrowBack from "../../icons/ArrowBack"
import ArrowLeft from "../../icons/ArrowLeft"
import { useNavigate } from "react-router-dom"

interface Step {
  label: string
  completed: boolean
}

interface StepComponentProps {
  steps: Step[]
  activeStep: number
  onNext: () => void
  onBack: () => void
  onSubmit: () => void
  isEditMode: boolean
  stepBody: React.ReactNode
}

const StepComponent = ({
  steps,
  activeStep,
  onNext,
  onBack,
  onSubmit,
  isEditMode,
  stepBody
}: StepComponentProps) => {
  const isCurrentStepComplete = steps[activeStep].completed
  const navigate = useNavigate()
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        width: "100%",
        gap: "32px",
        justifyContent: "space-between"
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        height="32px"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          width: "62%"
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer"
          }}
          onClick={() => navigate("/customproperty")}
        >
          <ArrowLeft
            sx={{
              color: "text.secondary",
              mr: "4px",
              width: "16px",
              height: "16px"
            }}
          />
          <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
            All Store Properties
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", marginBottom: "24px" }}>
        {steps.map((step, index) => {
          const isCompleted = step.completed
          const isActive = activeStep === index

          let backgroundColor, color, textColor, iconColor

          if (isCompleted) {
            backgroundColor = "primary.dark"
            color = "secondary.main"
            textColor = "secondary.main"
            iconColor = "secondary.main"
          } else if (isActive) {
            backgroundColor = "secondary.light"
            color = "secondary.main"
            textColor = "fileInput.dark"
            iconColor = "fileInput.dark"
          } else {
            backgroundColor = "secondary.main"
            color = "text.disabled"
            textColor = "text.disabled"
            iconColor = "text.disabled"
          }

          return (
            <React.Fragment key={index}>
              <Button
                variant={isCompleted ? "contained" : "outlined"}
                sx={{
                  backgroundColor,
                  color,
                  borderRadius: "4px",
                  height: "24px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <CheckMark sx={{ height: "14px", color: iconColor }} />
                <Typography
                  variant="subtitle1"
                  sx={{ color: textColor, marginLeft: "4px" }}
                >
                  {step.label}
                </Typography>
              </Button>
              {index < steps.length - 1 && (
                <ArrowForward sx={{ height: "14px", color: "text.disabled" }} />
              )}
            </React.Fragment>
          )
        })}
      </Box>
      <Box
        sx={{
          width: "100%",
          borderRadius: "8px",
          border: "1px solid",
          borderColor: "primary.contrastText",
          display: "flex",
          overflowY: "hidden",
          flexDirection: "column",
          marginBottom: "40px"
        }}
      >
        {stepBody}
      </Box>
      <Box
        sx={{
          width: "100%",
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1000,
          boxShadow: "0px -4px 12px rgba(0, 0, 0, 0.08)",
          backgroundColor: "secondary.main"
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            maxWidth: "100%",
            padding: "20px 120px"
          }}
        >
          <Button variant="outlined" onClick={onBack}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Typography
                variant="body2"
                sx={{ fontWeight: "600", color: "text.primary" }}
              >
                <ArrowBack sx={{ height: "12px" }} />
                {activeStep === 0 ? "Cancel" : "Previous"}
              </Typography>
            </Box>
          </Button>
          <Button
            variant="contained"
            disabled={!isCurrentStepComplete}
            onClick={activeStep === steps.length - 1 ? onSubmit : onNext}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontWeight: "600",
                  color: isCurrentStepComplete
                    ? "secondary.main"
                    : "secondary.dark",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                {activeStep === steps.length - 1
                  ? isEditMode
                    ? "Save Changes"
                    : "Complete Setup"
                  : `Next, ${steps[activeStep + 1]?.label}`}
                <ArrowForward
                  sx={{
                    height: "12px",
                    color: isCurrentStepComplete ? "inherit" : "secondary.dark"
                  }}
                />
              </Typography>
            </Box>
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default StepComponent
