import React from "react"
import TroubleLogin from "../TroubleLogin"
import EyeClosed from "../../icons/EyeClosed"
import EyeOpenFill from "../../icons/EyeOpenFill"
import InputAdornment from "@mui/material/InputAdornment"
import IconButton from "@mui/material/IconButton"
import { Box, Button, TextField, Typography } from "@mui/material"
import { useForm, Controller } from "react-hook-form"

type FormValues = {
  email: string
  password: string
}

interface Props {
  onSubmit: (data: FormValues) => void
}

const LoginForm = (props: Props) => {
  const { control, handleSubmit } = useForm<FormValues>({
    mode: "onSubmit"
  })

  const [email, setEmail] = React.useState("")
  const [showPassword, setShowPassword] = React.useState(false)

  const handleFormSubmit = (data: FormValues) => {
    props.onSubmit(data)
  }

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  return (
    <Box display="flex" flexDirection="column" gap="32px">
      <img
        src="/square_logo.svg"
        alt="GTV logo"
        style={{
          width: "72px",
          height: "72px"
        }}
      />
      <Typography variant="body1">
        Log in to manage your in-store digital marketing network.
      </Typography>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          gap="24px"
        >
          <Box
            sx={{
              width: "100%"
            }}
          >
            <Typography variant="body2">Work email</Typography>
            <Controller
              name="email"
              rules={{
                required: "Please enter a valid email format",
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: "Please enter a valid email format"
                }
              }}
              control={control}
              defaultValue=""
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  type="email"
                  placeholder="name@company.com"
                  error={!!error}
                  helperText={error ? error.message : null}
                  sx={{
                    width: "100%",
                    "& input": {
                      boxSizing: "border-box"
                    }
                  }}
                  onChange={(e) => {
                    field.onChange(e)
                    setEmail(e.target.value)
                  }}
                />
              )}
            />
          </Box>
          <Box
            sx={{
              width: "100%"
            }}
          >
            <Typography variant="body2">Password</Typography>
            <Controller
              name="password"
              rules={{
                required: "Please provide a password"
              }}
              control={control}
              defaultValue=""
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  error={!!error}
                  helperText={error ? error.message : null}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <EyeOpenFill /> : <EyeClosed />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  sx={{
                    width: "100%",
                    "& input": {
                      boxSizing: "border-box"
                    }
                  }}
                />
              )}
            />
          </Box>
          <Button
            data-testid="sumbit-login"
            variant="contained"
            type="submit"
            sx={{
              height: "36px",
              boxShadow: "none"
            }}
          >
            Log in
          </Button>
        </Box>
        <TroubleLogin initialEmail={email} />
      </form>
    </Box>
  )
}

export default LoginForm
