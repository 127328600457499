import React from "react"
import Typography from "@mui/material/Typography"

import Link from "@mui/material/Link"
import dayjs from "dayjs"
import Box from "@mui/material/Box"

const Copyright = () => {
  const currentYear = dayjs().format("YYYY")

  return (
    <Box
      component="div"
      color="secondary.dark"
      display={"flex"}
      alignItems={"center"}
      fontSize={"12px"}
      gap="2px"
    >
      <Typography
        variant="body2"
        display={"inline"}
        color="secondary.dark"
        fontSize={"18px"}
      >
        ©
      </Typography>
      <Typography variant="body2" fontSize="12px" color="secondary.dark">
        {currentYear}
      </Typography>
      <Link
        target="_blank"
        color="secondary.dark"
        href="https://grocerytv.com/"
        rel="noreferrer"
      >
        Grocery TV
      </Link>
      <Typography variant="body2" fontSize="12px" color="secondary.dark">
        All Rights Reserved.
      </Typography>
      <Link
        target="_blank"
        color="secondary.dark"
        href="https://grocerytv.com/terms/"
        rel="noreferrer"
      >
        Terms
      </Link>

      <Typography variant="body2" fontSize="12px" color="secondary.dark">
        &
      </Typography>

      <Link
        target="_blank"
        color="secondary.dark"
        href="https://grocerytv.com/privacy/"
        rel="noreferrer"
      >
        Privacy
      </Link>
    </Box>
  )
}

export default Copyright
