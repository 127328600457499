import * as Yup from "yup"

export interface StoreTargetMapping {
  storesId: number
  bannerName?: string
  store: string
  location: string
  propertyValueId: string
  propertyValue: string
}

export interface PropertyItem {
  value: string
}

export interface PropertyForm {
  propertyName: string
  propertyItems: PropertyItem[]
  propertyType: string
}

interface StoreAddress {
  street: string | null
  city: string | null
  state: string | null
  postal_code: string | null
}

export interface StoreBanner {
  id: number
  name: string
}

interface StoreProperty {
  property_id: number
  property_name: string
  value_id: number
  value_name: string
}

export interface Store {
  id: number
  name: string
  company_id: number
  installed: boolean
  banner: StoreBanner
  address: StoreAddress
  properties: StoreProperty[]
}

export const CustomPropertyFormSchema = Yup.object().shape({
  propertyName: Yup.string().required("Property name is required"),
  propertyType: Yup.string()
    .oneOf(["boolean", "multi-select"] as const)
    .default("multi-select")
    .required("Property type is required"),

  propertyItems: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string().required("Property Value is required"),
        valueId: Yup.string().optional(),
        isEditable: Yup.boolean().optional()
      })
    )

    .min(1, "At least one property value is required")
    .test("unique", "Duplicate values are not allowed", function (list) {
      if (!list) return true

      const duplicateIndexes = new Set()
      const mapped = list.map((item) => item.value.toLowerCase().trim())
      const isValid = mapped.length === new Set(mapped).size

      if (!isValid) {
        const map = new Map()
        mapped.forEach((value, idx) => {
          if (map.has(value)) {
            duplicateIndexes.add(idx)
            duplicateIndexes.add(map.get(value))
          }
          map.set(value, idx)
        })

        return this.createError({
          path: `propertyItems[${Array.from(duplicateIndexes)[0]}].value`,
          message: "No duplicate values",
          type: "unique"
        })
      }
      return true
    })
    .default([]),

  StoreTargetMappings: Yup.array()
    .of(
      Yup.object().shape({
        storesId: Yup.number().required(),
        bannerName: Yup.string().optional(),
        store: Yup.string().required(),
        location: Yup.string().required(),
        propertyValueId: Yup.string().required(),
        propertyValue: Yup.string().required()
      })
    )
    .default([]),

  notApplicable: Yup.object()
    .shape({
      valueId: Yup.string().default("notApplicable"),
      value: Yup.string().default("Not Applicable"),
      isEditable: Yup.boolean().optional()
    })
    .optional(),

  storeFilterView: Yup.string().required().default("unmapped"),
  storeSearchTerm: Yup.string().optional().default("")
})

// Infer the type from the schema
export type CustomPropertyFormModel = Yup.InferType<
  typeof CustomPropertyFormSchema
>
