import React from "react"
import { Box, Typography } from "@mui/material"
import { useQuery, gql } from "urql"
import EditUserForm from "../../user/EditUserForm"
import NavBar from "../../navbar"
import ErrorBanner from "../../banners/ErrorBanner"

const CURRENT_USER_QUERY = gql`
  query getUser($id: Int!) {
    userDetails(id: $id) {
      companiesId
      email
      id
      name
      phone
    }
  }
`

interface Props {
  userId: number
}

const EditAccount = (props: Props) => {
  const [{ data, error }] = useQuery({
    query: CURRENT_USER_QUERY,
    variables: {
      id: props.userId
    }
  })

  if (error) {
    return (
      <Box>
        <ErrorBanner>
          <Typography variant="body2" color="error">
            Something happened, try refreshing the page. If the issue persists,
            contact our support email for help.
          </Typography>
        </ErrorBanner>
      </Box>
    )
  }

  return (
    <Box sx={{ overflow: "hidden" }}>
      <NavBar />
      {data ? <EditUserForm user={data.userDetails} /> : null}
    </Box>
  )
}

export default EditAccount
