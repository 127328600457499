/* eslint-disable indent */
import React from "react"
import {
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import RemoveIcon from "@mui/icons-material/Remove"
import { matchSorter } from "match-sorter"
import SearchInput from "../inputs/SearchInput"
import Row from "./row"
import HeaderRow from "./headerRow"
import NoRowOverlay from "../campaigns/NoRowOverlay"
import ErrorBanner from "../banners/ErrorBanner"
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox"
import { useController, useFormContext } from "react-hook-form"
import { NewCampaignStore } from "../../models/campaign"
import { StoreBanner } from "src/models/storetarget"
import { useFlags } from "launchdarkly-react-client-sdk"

interface PropertyValueApiReponse {
  id: number
  name: string
  is_editable: boolean
}

interface PropertyApiResponse {
  id: number
  name: string
  type: string
  values: PropertyValueApiReponse[]
}

interface Props {
  data: NewCampaignStore[]
  isCampaignEnded?: boolean
  isLoadingStores: boolean
  properties: PropertyApiResponse[] | undefined
  bannerList?: StoreBanner[]
  stateList?: string[]
  isBannerExpanded: boolean
  isStateExpanded: boolean
}

const TargetGrid = ({
  data,
  isCampaignEnded,
  isLoadingStores,
  properties,
  bannerList,
  stateList,
  isBannerExpanded,
  isStateExpanded
}: Props) => {
  const { control, watch, formState, setValue } = useFormContext()
  const { errors } = formState
  const selectedPropertyValues: number[] = watch("selectedPropertyValues", [])
  const [expandedPanels, setExpandedPanels] = React.useState<string[]>([])

  const storeSearchTerm = watch("storeSearchTerm")

  const selectedBanners: number[] = watch("selectedBanners", [])
  const selectedStates: string[] = watch("selectedStates", [])
  const [targets, setTargets] = React.useState(data)
  const { storeProperties } = useFlags()

  const columns = ["Banner", "Store", "City,State"]

  const [sortTargetsByColumn, setSortTargetsByColumn] = React.useState(
    columns[0]
  )

  const [sortTargetsDirection, setSortTargetsDirection] = React.useState<
    "asc" | "desc"
  >("asc")

  React.useEffect(() => {
    let currentViewData = [...data]

    if (storeSearchTerm && storeSearchTerm !== "") {
      currentViewData = matchSorter(currentViewData, storeSearchTerm, {
        keys: ["name", "banner.name", "address.state", "address.city"]
      })
    }

    setTargets(currentViewData)
  }, [data, storeSearchTerm])

  React.useEffect(() => {
    const initialPanels: string[] = []
    if (isBannerExpanded) {
      initialPanels.push("panel-banner")
    }
    if (isStateExpanded) {
      initialPanels.push("panel-state")
    }
    // Add panels for properties that have selected values
    if (properties && selectedPropertyValues.length > 0) {
      properties.forEach((property) => {
        if (
          property.values.some((value) =>
            selectedPropertyValues.includes(value.id)
          )
        ) {
          initialPanels.push(`panel-${property.id}`)
        }
      })
    }
    setExpandedPanels(initialPanels)
  }, [isBannerExpanded, isStateExpanded])

  const { field: searchField } = useController({
    name: "storeSearchTerm",
    control
  })

  const { field: activeStoresField } = useController({
    name: "activeStores",
    control
  })

  const activeStores: number[] = watch("activeStores", [])

  const [targetCount, setTargetCount] = React.useState(data.length)

  const selectedTargetCount = React.useMemo(
    () => activeStores?.length,
    [activeStores]
  )

  const isAllTargetsSelected = React.useMemo(() => {
    if (activeStores.length < 1) {
      return false
    }

    return targets.every(
      (store) =>
        activeStores.findIndex((activeStore) => activeStore === store.id) !== -1
    )
  }, [activeStores, targets])

  const isTargetSelected = React.useCallback(
    (targetId: number) => activeStores.some((store) => store === targetId),
    [activeStores, targets]
  )

  React.useEffect(() => {
    setTargetCount(data.length)
  }, [data.length])

  const counterPrefix: "Deselect" | "Select" = React.useMemo(() => {
    if (isAllTargetsSelected) {
      return "Deselect"
    } else {
      return "Select"
    }
  }, [isAllTargetsSelected])

  const onSelectAllTargets = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (checked) {
        const allTargetIds = targets.map((target) => target.id)
        activeStoresField.onChange(allTargetIds)
      } else {
        activeStoresField.onChange([])
      }
    },
    [activeStoresField, targets]
  )

  const sortedTargets = React.useMemo(() => {
    const getValue = (target: NewCampaignStore) => {
      switch (sortTargetsByColumn) {
        case "Banner":
          return target.banner?.name.toLowerCase() ?? ""
        case "Store":
          return target.name.toLowerCase()
        case "City,State":
          return `${target.address?.city}, ${target.address?.state}`.toLowerCase()
        default:
          return ""
      }
    }

    const sorter = (a: NewCampaignStore, b: NewCampaignStore) => {
      const valueA = getValue(a)
      const valueB = getValue(b)

      if (!isNaN(Number(valueA)) && !isNaN(Number(valueB))) {
        return sortTargetsDirection === "asc"
          ? Number(valueA) - Number(valueB)
          : Number(valueB) - Number(valueA)
      }

      if (sortTargetsDirection === "asc") {
        return valueA.toString().localeCompare(valueB.toString())
      } else {
        return valueB.toString().localeCompare(valueA.toString())
      }
    }

    return [...targets].sort(sorter)
  }, [targets, sortTargetsByColumn, sortTargetsDirection])
  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedPanels((prev) =>
        isExpanded ? [...prev, panel] : prev.filter((p) => p !== panel)
      )
    }

  const handleValueChange = (valueId: number) => {
    const newSelectedValues = selectedPropertyValues.includes(valueId)
      ? selectedPropertyValues.filter((id) => id !== valueId)
      : [...selectedPropertyValues, valueId]
    setValue("selectedPropertyValues", newSelectedValues)
  }

  const handleBannerChange = (bannerId: number) => {
    const newSelectedBanners = selectedBanners.includes(bannerId)
      ? selectedBanners.filter((id) => id !== bannerId)
      : [...selectedBanners, bannerId]
    setValue("selectedBanners", newSelectedBanners)
  }

  const handleStateChange = (state: string) => {
    const newSelectedStates = selectedStates.includes(state)
      ? selectedStates.filter((s) => s !== state)
      : [...selectedStates, state]
    setValue("selectedStates", newSelectedStates)
  }

  const handleSortChange = (columnName: string) => {
    if (columnName === sortTargetsByColumn) {
      setSortTargetsDirection(sortTargetsDirection === "asc" ? "desc" : "asc")
    } else {
      setSortTargetsByColumn(columnName)
      setSortTargetsDirection("asc")
    }
  }

  const counterLabel: string = React.useMemo(() => {
    return selectedTargetCount === 0
      ? `${counterPrefix} All (-)`
      : `${counterPrefix} All (${selectedTargetCount} of ${targetCount} selected)`
  }, [selectedTargetCount, targetCount, counterPrefix])

  const selectedZones = watch("selectedZones") || []
  const selectedZonePlacements = watch("selectedZonePlacements") || []

  const hasNoSelections =
    selectedZones.length === 0 && selectedZonePlacements.length === 0

  if (hasNoSelections) {
    return (
      <Box className="target-grid" data-id="no-store-area-selected-wrapper">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            gap: "8px"
          }}
        >
          <img src="/Info.jpg" />
          <Typography variant="body2">
            To select targeting, you need to first select a store area or
            placement above.
          </Typography>
        </Box>
      </Box>
    )
  }

  return (
    <Box className="target-grid">
      {errors.activeStores?.message && (
        <ErrorBanner>{errors.activeStores?.message as string}</ErrorBanner>
      )}

      <Box
        display={"flex"}
        mt="8px"
        justifyContent={"space-between"}
        sx={{
          alignItems: "center",
          lineHeight: "54px"
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={isAllTargetsSelected}
              size="small"
              disabled={targets.length === 0 || isCampaignEnded}
              sx={{
                color: "text.primary",
                fontSize: "14px",
                "&.Mui-checked": {
                  color: "primary.light"
                }
              }}
              style={{
                fontSize: "14px",
                marginLeft: "0px"
              }}
              onChange={onSelectAllTargets}
              indeterminate={selectedTargetCount > 0 && !isAllTargetsSelected}
              indeterminateIcon={
                <IndeterminateCheckBoxIcon
                  sx={{ color: (theme) => `${theme.palette.primary.light}` }}
                />
              }
            />
          }
          disableTypography={true}
          label={counterLabel}
          sx={{
            fontSize: "14px",
            marginLeft: "0px",
            color: targets.length === 0 ? "disabled.main" : "inherit",
            opacity: isCampaignEnded ? 0.5 : 1,
            fontWeight:
              isAllTargetsSelected || selectedTargetCount > 0 ? 600 : 500
          }}
        />
        <Box
          sx={{
            display: "flex",
            position: "relative",
            gap: "12px"
          }}
        >
          {!isCampaignEnded && (
            <SearchInput
              searchInput={searchField.value}
              onChange={(e) => {
                searchField.onChange(e)
              }}
              onClear={() => searchField.onChange("")}
              width="260px"
            />
          )}
        </Box>
      </Box>
      <Box sx={{ display: "flex", gap: "12px" }}>
        <Box
          sx={{
            border: "1px solid ",
            borderColor: "primary.contrastText",
            borderRadius: "8px",
            overflow: "hidden",
            height: "452px",
            width: !isCampaignEnded ? "70%" : "100%"
          }}
        >
          <Box
            sx={{
              display: "flex",
              overflow: "auto",
              height: "auto",
              maxHeight: "452px",
              justifyContent: "center"
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1
              }}
            >
              {storeSearchTerm !== undefined &&
              targets.length === 0 &&
              !isLoadingStores ? (
                <NoRowOverlay
                  searchInput={storeSearchTerm}
                  handleClearInput={() => searchField.onChange()}
                />
              ) : (
                <>
                  <Box
                    sx={{
                      position: "sticky",
                      top: 0,
                      zIndex: 1
                    }}
                  >
                    <HeaderRow
                      isCampaignEnded={isCampaignEnded}
                      columns={columns}
                      onSortChange={handleSortChange}
                      sortTargetsByColumn={sortTargetsByColumn}
                      sortTargetsDirection={sortTargetsDirection}
                    />
                  </Box>
                  <Box sx={{ position: "relative", height: "420px" }}>
                    {isLoadingStores ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          zIndex: 2
                        }}
                      >
                        <CircularProgress />
                      </Box>
                    ) : (
                      sortedTargets.map((target) => (
                        <Row
                          key={target.id}
                          isCampaignEnded={isCampaignEnded}
                          target={target}
                          selected={isTargetSelected(target.id)}
                          onChange={() => {
                            const index = activeStores.findIndex(
                              (store: number) => store === target.id
                            )
                            if (index === -1) {
                              activeStoresField.onChange([
                                ...activeStores,
                                target.id
                              ])
                            } else {
                              activeStoresField.onChange(
                                activeStores.filter(
                                  (store) => store !== target.id
                                )
                              )
                            }
                          }}
                        />
                      ))
                    )}
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Box>
        {!isCampaignEnded && (
          <Box
            sx={{ display: "flex", flexDirection: "column", width: "260px" }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                border: "1px solid",
                borderColor: "primary.contrastText",
                borderRadius: "8px",
                overflow: "hidden"
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  padding: "8px 16px"
                }}
              >
                <Typography variant="body2" sx={{ color: "fileInput.dark" }}>
                  Filters
                </Typography>
              </Box>

              {bannerList && bannerList.length > 1 && (
                <Box
                  sx={{
                    display: "flex",
                    borderTop: "1px solid",
                    borderColor: "primary.contrastText"
                  }}
                >
                  <Accordion
                    expanded={expandedPanels.includes("panel-banner")}
                    onChange={handleAccordionChange("panel-banner")}
                    sx={{
                      boxShadow: "none",
                      width: "100%",
                      "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                        transform: "rotate(0deg)"
                      }
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        expandedPanels.includes("panel-banner") ? (
                          <RemoveIcon sx={{ width: "16px", height: "16px" }} />
                        ) : (
                          <AddIcon sx={{ width: "16px", height: "16px" }} />
                        )
                      }
                      sx={{
                        minHeight: "40px",
                        "&.Mui-expanded": {
                          minHeight: "40px",
                          margin: 0
                        },
                        "& .MuiAccordionSummary-content": {
                          margin: "0"
                        }
                      }}
                    >
                      <Typography variant="body2">Banner</Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "0 12px",
                        paddingBottom: "8px"
                      }}
                    >
                      {bannerList.map((banner) => (
                        <FormControlLabel
                          key={banner.id}
                          control={
                            <Checkbox
                              value={banner.id}
                              checked={selectedBanners.includes(banner.id)}
                              onChange={() => handleBannerChange(banner.id)}
                              sx={{
                                color: "secondary.dark",
                                height: "32px",
                                "&.Mui-checked": {
                                  color: "primary.light"
                                }
                              }}
                            />
                          }
                          label={banner.name}
                          sx={{ height: "32px" }}
                        />
                      ))}
                    </AccordionDetails>
                  </Accordion>
                </Box>
              )}
              {stateList && stateList.length > 1 && (
                <Box
                  sx={{
                    display: "flex",
                    borderTop: "1px solid",
                    borderColor: "primary.contrastText"
                  }}
                >
                  <Accordion
                    expanded={expandedPanels.includes("panel-state")}
                    onChange={handleAccordionChange("panel-state")}
                    sx={{
                      boxShadow: "none",
                      width: "100%",
                      "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                        transform: "rotate(0deg)"
                      }
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        expandedPanels.includes("panel-state") ? (
                          <RemoveIcon sx={{ width: "16px", height: "16px" }} />
                        ) : (
                          <AddIcon sx={{ width: "16px", height: "16px" }} />
                        )
                      }
                      sx={{
                        minHeight: "40px",
                        "&.Mui-expanded": {
                          minHeight: "40px",
                          margin: 0
                        },
                        "& .MuiAccordionSummary-content": {
                          margin: "0"
                        }
                      }}
                    >
                      <Typography variant="body2">State</Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "0 12px",
                        paddingBottom: "8px"
                      }}
                    >
                      {stateList.map((state) => (
                        <FormControlLabel
                          key={state}
                          control={
                            <Checkbox
                              value={state}
                              checked={selectedStates.includes(state)}
                              onChange={() => handleStateChange(state)}
                              sx={{
                                color: "secondary.dark",
                                height: "32px",
                                "&.Mui-checked": {
                                  color: "primary.light"
                                }
                              }}
                            />
                          }
                          label={state}
                          sx={{ height: "32px" }}
                        />
                      ))}
                    </AccordionDetails>
                  </Accordion>
                </Box>
              )}

              {storeProperties &&
                properties &&
                properties.map((property) => (
                  <Box
                    key={property.id}
                    sx={{
                      display: "flex",
                      borderTop: "1px solid",
                      borderColor: "primary.contrastText"
                    }}
                  >
                    <Accordion
                      key={property.id}
                      expanded={expandedPanels.includes(`panel-${property.id}`)}
                      onChange={handleAccordionChange(`panel-${property.id}`)}
                      sx={{
                        boxShadow: "none",
                        width: "100%",
                        "&:last-of-type": {
                          borderBottomLeftRadius: "8px",
                          borderBottomRightRadius: "8px"
                        },
                        "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded":
                          {
                            transform: "rotate(0deg)"
                          }
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          expandedPanels.includes(`panel-${property.id}`) ? (
                            <RemoveIcon
                              sx={{ width: "16px", height: "16px" }}
                            />
                          ) : (
                            <AddIcon sx={{ width: "16px", height: "16px" }} />
                          )
                        }
                        aria-controls={`panel-${property.id}-content`}
                        id={`panel-${property.id}-header`}
                        sx={{
                          minHeight: "40px",
                          "&.Mui-expanded": {
                            minHeight: "40px",
                            margin: 0
                          },
                          "& .MuiAccordionSummary-content": {
                            margin: "0"
                          }
                        }}
                      >
                        <Typography variant="body2">{property.name}</Typography>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          padding: "0 12px",
                          paddingBottom: "8px"
                        }}
                      >
                        {property.values
                          .filter((value) => value.name !== "Not Applicable")
                          .map((value) => (
                            <FormControlLabel
                              key={value.id}
                              control={
                                <Checkbox
                                  value={value.id}
                                  checked={selectedPropertyValues.includes(
                                    value.id
                                  )}
                                  onChange={() => handleValueChange(value.id)}
                                  sx={{
                                    color: "secondary.dark",
                                    height: "32px",
                                    "&.Mui-checked": {
                                      color: "primary.light"
                                    }
                                  }}
                                />
                              }
                              label={value.name}
                              sx={{ height: "32px" }}
                            />
                          ))}
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                ))}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default TargetGrid
