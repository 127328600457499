import React from "react"
import { Alert, Box } from "@mui/material"
import CheckMark from "../../icons/CheckMark"

type SuccessChangeBannerProps = {
  children: React.ReactNode
}

const SuccessChangeBanner: React.FC<SuccessChangeBannerProps> = ({
  children
}) => {
  return (
    <Box justifyContent="center" alignItems="center">
      <Alert
        icon={
          <CheckMark
            sx={{ width: "16px", height: "16px", color: "secondary.main" }}
          />
        }
        severity="success"
        sx={{
          height: "32px",
          backgroundColor: "asset.main",
          color: "secondary.main",
          borderRadius: "4px",
          alignItems: "center"
        }}
      >
        {children}
      </Alert>
    </Box>
  )
}

export default SuccessChangeBanner
