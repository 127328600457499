import React from "react"
import {
  Accordion,
  Box,
  AccordionSummary,
  AccordionDetails,
  Typography
} from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { useNavigate } from "react-router-dom"

type TroubleLoginProps = {
  initialEmail: string
}

const TroubleLogin = ({ initialEmail }: TroubleLoginProps) => {
  const [expanded, setExpanded] = React.useState<string | false>(false)
  const navigate = useNavigate()

  const HandleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  const HandleResetPassword = () => {
    navigate("/reset_password", { state: { initialEmail } })
  }

  return (
    <Box sx={{ height: "54px" }}>
      <Accordion
        expanded={expanded === "login-trouble-panel"}
        onChange={HandleChange("login-trouble-panel")}
        sx={{ boxShadow: 0, padding: "8px" }}
      >
        <AccordionSummary
          aria-controls="login-trouble-panelbh-content"
          id="login-trouble-panelbh-header"
          sx={{
            padding: "0px",
            height: "54px",
            "&.Mui-expanded": { minHeight: "54px", maxHeight: "54px" }
          }}
        >
          <Box sx={{ display: "flex", gap: "4px", alignItems: "center" }}>
            <Typography variant="body2">Trouble logging in?</Typography>
            <ExpandMoreIcon
              sx={{
                transform:
                  expanded === "login-trouble-panel"
                    ? "rotate(180deg)"
                    : "rotate(0)",
                transition: "transform 0.3s"
              }}
            />
          </Box>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "12px",
            padding: "0px "
          }}
        >
          <Typography
            variant="body2"
            onClick={HandleResetPassword}
            color="text.secondary"
            sx={{
              "&:hover": { cursor: "pointer", textDecoration: "underline" }
            }}
          >
            Reset Password
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default TroubleLogin
