import React from "react"
import { Box, Typography } from "@mui/material"

interface HeaderRowProps {
  columns: string[]
}

const StoreMappingHeaderRow = ({ columns }: HeaderRowProps) => (
  <Box
    sx={{
      display: "grid",
      gridTemplateColumns:
        columns.length === 3 ? "1.5fr 1.5fr 1fr" : "1.5fr 1.5fr 1fr 1fr",
      padding: "2px 0",
      backgroundColor: "secondary.main",
      fontWeight: "bold"
    }}
  >
    {columns.map((column) => (
      <Box
        key={column}
        sx={{
          display: "flex",
          justifyContent: "start",
          padding: "0 12px",
          alignItems: "center"
        }}
        gap="2px"
      >
        <Typography
          sx={{ lineHeight: "32px", fontSize: "12px", fontWeight: 600 }}
          variant="body2"
        >
          {column}
        </Typography>
      </Box>
    ))}
  </Box>
)

export default StoreMappingHeaderRow
