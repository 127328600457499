import React from "react"
import { AppBar, Box, Toolbar, Button, Typography } from "@mui/material"

interface Props {
  onUserCancel: () => void
  publishDisabled: boolean
  cancelDisabled: boolean
  campaignId?: number
  onEndNow?: (e: React.MouseEvent) => void
  showEndNow?: boolean
}

const EditCampaignNavBar = (props: Props) => {
  return (
    <AppBar
      position="fixed"
      color="inherit"
      elevation={1}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        padding: "0px 10%",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        boxShadow: "none",
        borderBottom: (theme) => `1px solid ${theme.palette.secondary.light}`
      }}
    >
      <Toolbar
        sx={{
          padding: 0,
          "&.MuiToolbar-root": {
            paddingLeft: 0,
            paddingRight: 0
          }
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          sx={{
            height: "28px"
          }}
        >
          <Button
            variant="outlined"
            disabled={props.cancelDisabled}
            onClick={props.onUserCancel}
            sx={{ color: "text.primary" }}
          >
            Cancel
          </Button>
        </Box>
        <Box flexGrow={1} display="flex" justifyContent="space-around">
          <Typography>
            {props.campaignId ? "Edit Campaign" : "Create Campaign"}
          </Typography>
        </Box>
        <Box display="flex" gap={2}>
          {props.campaignId && props.showEndNow && (
            <Button
              variant="outlined"
              sx={{
                backgroundColor: "error.main",
                "&:hover": {
                  backgroundColor: "error.dark"
                }
              }}
              onClick={(e) => {
                e.preventDefault()
                props.onEndNow?.(e)
              }}
            >
              <Typography variant="body2" sx={{ color: "secondary.main" }}>
                End Now
              </Typography>
            </Button>
          )}
          <Button
            variant="contained"
            sx={{
              boxShadow: "none"
            }}
            disabled={props.publishDisabled}
            type="submit"
          >
            {props.campaignId ? "Save" : "Publish"}
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default EditCampaignNavBar
