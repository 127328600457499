import React from "react"
import { Alert, Box } from "@mui/material"
import TriangleExlcamationFill from "../../icons/TriangleExlcamationFill"

type WarningBannerProps = {
  children: React.ReactNode
}

const WarningBanner: React.FC<WarningBannerProps> = ({ children }) => {
  return (
    <Box justifyContent="center">
      <Alert
        icon={
          <TriangleExlcamationFill
            sx={{ width: "16px", height: "16px", color: "hint.dark" }}
          />
        }
        severity="warning"
        sx={{
          height: "32px",
          backgroundColor: "hint.contrastText",
          color: "hint.dark",
          borderRadius: "4px",
          alignItems: "center"
        }}
      >
        {children}
      </Alert>
    </Box>
  )
}

export default WarningBanner
