import React from "react"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const PlayFill = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5 4.80902C5 4.43733 5.39116 4.19558 5.72361 4.3618L20.1056 11.5528C20.4741 11.737 20.4741 12.263 20.1056 12.4472L5.72361 19.6382C5.39116 19.8044 5 19.5627 5 19.191V4.80902Z"
          fill="black"
        />
      </svg>
    </SvgIcon>
  )
}

export default PlayFill
