import * as Yup from "yup"

export type User = {
  id: number
  companyName?: string
  name: string
  email: string
  phone?: string
  companiesId?: number
}

export const UserFormSchema = Yup.object().shape({
  userFName: Yup.string().required("First name is required"),
  userLName: Yup.string().required("Last name is required"),
  userEmail: Yup.string()
    .email("Must be a valid email")
    .required("Email is required"),
  userPhone: Yup.string()
})

export type UserFormModel = Yup.InferType<typeof UserFormSchema>
